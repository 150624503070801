<template>
  <div class="datasource">
    <div class="databox">
      <div v-if="curcard" class="cardname wordellipsis">
        {{ curcard.isdelete ? '（已删除）' : '' }}{{ curcard.name }}
        <div class="namehover"><span @click="delcurcard">删除</span><span @click="hrefedit">编辑</span><span @click="freshcard">更新</span></div>
      </div>
      <el-popover v-if="!curcard" popper-class="card-popper" placement="bottom-start" title=" " width="358" trigger="click" v-model="visible">
        <div class="cardmenu">
          <div class="filterbox dis_flex">
            <el-input v-model="listKeyWord" placeholder="请输入卡片关键字" size="small" @input="debounce" />
            <i @click="refreshcard" class="el-icon-refresh-right"></i>
          </div>

          <el-menu class="custom_menu" :default-openeds="defaultOpeneds" @select="handleSelect">
            <el-submenu :index="midx.toString()" v-for="(menu, midx) in showList" :key="midx">
              <template slot="title">
                <i class="el-icon-caret-right"></i>
                <span>{{ menu.name }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :key="cardidx"
                  :index="midx + '-' + cardidx"
                  v-for="(card, cardidx) in menu.bookmarks"
                  class="aaaa"
                  :class="{ checked: card.checked }"
                  >{{ card.name }}<b>({{ card.id }})</b></el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <el-button slot="reference" class="addbtn" type="text" size="small"><i class="plusicon">+</i>选择卡片</el-button>
      </el-popover>
      <el-button v-if="!curcard" @click="creatcard" class="addbtn newbtn" type="text" size="small"><i class="plusicon">+</i>新建卡片</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getpptmetadata, getBookmark } from '@/api/reportAPI.js'
import BI_config from '@/utils/config'

export default {
  props: ['tempitem'],
  data() {
    return {
      visible: false,
      curcard: null,
      listKeyWord: '',
      defaultOpeneds: [],
      copyList: [],
      showList: [],
      debounce: this.$commonJs2.debounce(this.filterList, 1000),
    }
  },
  computed: {
    ...mapState({
      cardlists: state => state.cardlists,
    }),
  },

  async created() {
    if (this.cardlists.length > 0) {
      this.init()
    } else {
      this.getcardlist()
    }
  },
  methods: {
    init() {
      let allbookmarks = []
      const copylist = JSON.parse(JSON.stringify(this.cardlists))
      this.copyList = copylist.filter(x => {
        if (x.bookmarks.length > 0) {
          allbookmarks = allbookmarks.concat(x.bookmarks)
          return x
        }
      })
      this.showList = JSON.parse(JSON.stringify(this.copyList))

      if (this.tempitem.chartdata) {
        this.curcard = allbookmarks.find(x => x.id === this.tempitem.chartdata.id)
      }
    },
    async getcardlist() {
      const res = await getpptmetadata()
      this.$store.commit('setCardlists', res.data)
      this.init()
    },
    filterList() {
      console.time()
      let copylist = JSON.parse(JSON.stringify(this.copyList))
      console.timeEnd()
      if (this.listKeyWord) {
        copylist.forEach(x => {
          console.time()
          x.bookmarks = x.bookmarks.filter(m => {
            return this.$commonJs2.matchSoftly(m.name, this.listKeyWord) || String(m.id).includes(this.listKeyWord)
          })
          console.timeEnd()
        })
        copylist.forEach((x, sidx) => {
          this.defaultOpeneds.push(sidx.toString())
        })
      }
      this.showList = copylist
    },
    //选中卡片 更新仪表盘内卡片
    handleSelect(key, keypath) {
      const splitkey = key.split('-')
      this.curcard = this.showList[keypath[0]].bookmarks[splitkey[1]]
      this.visible = false
      this.$emit('cardchange', this.curcard)
    },
    delcurcard() {
      this.curcard = null
      this.$emit('cardchange', '')
    },
    hrefedit() {
      if (this.curcard.isdelete) {
        this.$confirm('该卡片已删除，不支持编辑！', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning',
        })
        return
      }
      let routes = this.$router.resolve({ path: `${BI_config.analysisProxy}/${this.curcard.category}/${this.$route.params.projectID}/${this.curcard.id}` })
      window.open(routes.href, '_blank')
    },
    async freshcard() {
      const id = this.tempitem.chartdata.id

      this.$emit('cardchange', '')
      const res = await getBookmark(id)
      const newcard = {
        ...res.data,
        id: id,
      }
      this.$emit('cardchange', newcard)
    },
    creatcard() {
      let routes = this.$router.resolve({ path: `${BI_config.analysisProxy}/behavior/${this.$route.params.projectID}` })
      window.open(routes.href, '_blank')
    },
    refreshcard() {
      this.getcardlist()
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.datasource {
  margin-bottom: 15px;
  .databox {
    .cardname {
      background: #f5f7fa;
      padding: 3px 10px;
      min-height: 25px;
      border-radius: 20px;
      word-break: break-all;
      text-align: center;
      position: relative;
      &:hover {
        .namehover {
          display: block;
        }
      }
      .namehover {
        display: none;
        width: 100%;
        height: 100%;
        background: #f5f7fa;
        text-align: center;
        line-height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        span {
          margin: 0 5px;
          cursor: pointer;
          &:hover {
            @include high_color1($highcolor-cheng);
          }
        }
      }
    }
    .addbtn {
      font-size: 14px;
      margin-bottom: 5px;
      @include high_color1($highcolor-cheng);
    }
    .newbtn {
      margin-left: 20px;
    }
  }
}
</style>
<style lang="scss">
.card-popper {
  width: 348px;
  padding-right: 0;
}
.cardmenu {
  .filterbox {
    margin-right: 10px;
    i {
      display: inline-block;
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      background: #ececec;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .custom_menu {
    border-right: 0;
    // background: #f7f7f7;
    max-height: 400px;
    overflow: auto;
    .checked {
      color: #ff8500;
    }
    .el-submenu {
      &.is-opened {
        .el-icon-caret-right {
          transform: rotateZ(90deg);
        }
      }
      .el-submenu__icon-arrow {
        display: none;
      }
      .el-submenu__title {
        height: 40px;
        line-height: 40px;
        padding: 0 !important;
      }
      .el-menu {
        // background: #f7f7f7;
        .el-menu-item-group {
          .el-menu-item-group__title {
            display: none;
          }
          ul {
            li {
              height: 32px;
              line-height: 32px;
              padding-left: 20px !important;
              padding-right: 0 !important;
              b {
                font-weight: normal;
                color: #999;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
