
import SparkMD5 from 'spark-md5'
const reportJS = {
  initcarddefinition(curele, definition) {
    const cardinfodef = curele?.chartdata?.cardinfo?.definition
    const injects = curele?.chartdata?.injectsearchparam ?? ''
    const settings = curele?.chartdata?.reqObj?.settings ?? ''
    if (definition || cardinfodef) {
      let parsedef = definition ? JSON.parse(definition.definition) : JSON.parse(cardinfodef)
      if (!definition) {
        definition = curele?.chartdata?.cardinfo
      }
      if (injects) {
        const contrastType = injects.contrastType || ''
        const injectgroup = (injects.groups && injects.groups[0]) || ''
        const timeRanges = injects.timeRanges || ''
        if (contrastType) {
          parsedef.parameter.contrastType = contrastType
        }
        if (injectgroup) {
          const groups = parsedef.parameter.groups.map(x => {
            if (x.code == injectgroup.code) {
              x = injectgroup
            }
            return x
          })
          parsedef.parameter.groups = groups
        }
        if (timeRanges) {
          parsedef.parameter.timeRanges = timeRanges
        }
      }
      if (settings) {
        parsedef.settings = settings
      }
      definition.definition = JSON.stringify(parsedef)
      return JSON.stringify(definition)
    } else {
      return ''
    }
  },
  async getMD5(file){
    let hashMd5 = SparkMD5.ArrayBuffer.hash
    let asyncFile = await reportJS.readFile(file)
    const md5 = hashMd5(asyncFile)
    return md5
  },
  readFile(file) {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onerror = error => {
        reader.abort()
        reject(error)
      }
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.readAsArrayBuffer(file)
    })
  },
}

export default reportJS
