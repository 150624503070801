<template>
  <div class="pptPage">
    <div class="pptMain">
      <div class="ppt-head">
        <extDatetime v-if="currentPage.slideType === 'Chart'" :proptimeidx="-1" @timechange="getAlltime" class="alltime" />
        <el-button v-if="$commonJs._point('BI_F0036')" type="primary" size="small" @click="saveslide">保 存</el-button>
      </div>
      <div class="exportbox">
        <pptPageItem v-if="currentPage && currentPage.id" ref="pptref" :pageList="currentPage" @drawer-change="drawerChange" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import reportJS from '@/views/Report/report.js'
import pptPageItem from './pageItem/pptPageItem'
import { putpresentationslide, postchartupload } from '@/api/reportAPI'

export default {
  props: ['pptData', 'changePages'],
  data() {
    return {
      currentPage: '', //当前ppt页面
    }
  },
  components: { pptPageItem },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  watch: {},
  created() {
    this.init()
  },
  methods: {
    init() {
      const slideID = this.$route.params.slideID
      const copySlides = _.cloneDeep(this.pptData.slides)
      let changepage = this.changePages.filter(x => x.id === Number(slideID))
      if (changepage.length === 0) {
        changepage = copySlides.filter(x => x.id === Number(slideID))
        this.currentPage = changepage.find(x => {
          x.settings = JSON.parse(x.settings)
          x.elements = x.elements.map(ele => {
            if (ele.definition) {
              ele.definition = JSON.parse(ele.definition)
            }
            if (ele.content) {
              ele.content = JSON.parse(ele.content)
            }
            if (ele.settings) {
              ele.settings = JSON.parse(ele.settings)
            }
            return ele
          })
          return x.id === Number(slideID)
        })
      } else {
        this.currentPage = changepage.find(x => {
          return x.id === Number(slideID)
        })
      }

      if (!this.currentPage) {
        this.$message.error('ppt没有当前页面')
        return
      }
    },
    drawerChange(item) {
      this.currentPage.elements = item
    },
    getAlltime(time) {
      this.$refs['pptref'].alltimechange(time)
    },
    saveslide() {
      this.$emit('savepages')
    },
    putslide(loading) {
      const elements = this.$refs['pptref'].exportpptData()
      let currentPage = JSON.parse(JSON.stringify(this.currentPage))
      currentPage.settings = JSON.stringify(currentPage.settings)
      currentPage.elements = elements.map((ele, eidx) => {
        let copyele = JSON.parse(JSON.stringify(ele))
        const curele = currentPage.elements[eidx]
        if (copyele.definition) {
          copyele.definition = reportJS.initcarddefinition(curele, copyele.definition)
        }
        copyele.content = curele.content ? JSON.stringify(curele.content) : curele.content
        copyele.settings = copyele.settings ? JSON.stringify(copyele.settings) : copyele.settings
        return copyele
      })
      const param = {
        presentationID: Number(this.$route.params.pptID),
        slideID: Number(this.$route.params.slideID),
        ...currentPage,
      }
      putpresentationslide(param)
        .then(v => {
          loading.close()
          this.$emit('change-ppt')
        })
        .catch(err => {
          console.error(err)
          loading.close()
        })
    },
  },
  mounted() {
    this.$eventBus.$on('propcurrentPage', () => {
      this.$eventBus.$emit('getcurrentPage', this.currentPage)
    })
  },
  destroyed() {
    this.$eventBus.$off('propcurrentPage')
  },
}
</script>

<style lang="scss" scoped>
.ceshibtn {
  position: absolute;
}
.ceshibtn2 {
  position: absolute;
  top: 250px;
}
.pptPage {
  height: 100%;
  margin: 0 20px 0;
  .pptMain {
    margin: 0 auto 0;
    width: 1424px;
    position: relative;
    .ppt-head {
      height: 60px;
      @include theme_bg1($theme-light);
      margin-bottom: 10px;
      .alltime {
        float: left;
        margin-top: 10px;
        margin-left: 15px;
        ::v-deep .el-input__inner,
        ::v-deep .el-button {
          @include theme_border_color($theme-border-light);
          @include theme_bg1($theme-light);
          &:hover {
            @include theme_color2($theme-light);
          }
        }
        ::v-deep .el-range-input {
          @include theme_bg1($theme-light);
        }
      }
      > .el-button {
        float: right;
        margin-top: 14px;
        margin-right: 15px;
        border-radius: 20px;
        &.el-button--primary {
          @include high_bg1($highcolor-cheng);
          @include high_border1($highcolor-cheng);
        }
      }
    }
    .exportbox {
      width: 1424px;
      height: 801px;
    }
  }
}
</style>
