<template>
  <div class="pptItem" :style="{ background: '#' + pageList.settings.background }">
    <div v-for="(temp, i) in innerList" :key="temp.itemkey" :style="tempstyle(temp)" :class="{ selected: activeidx === i }" class="tempItem">
      <div v-if="$commonJs._point('BI_F0036') && ['Chart'].includes(temp.elementType) && (temp.definition || temp.chartdata)" class="tempset">
        <span @click="edit(i)" class="el-icon-edit"></span>
        <span @click="del(i)" class="iconfont iconshanchu"></span>
      </div>
      <component
        :is="temp.elementType + 'Temp'"
        :ref="'tempref' + i"
        @click.stop.native="clicktemp(temp, i)"
        :pageitem="pageList"
        :tempitem="temp"
        class="temp"
        @catalogue-change="cataidx => cataloguechange(cataidx, i)"
        @chart-change="chart => chartChange(chart, i)"
        @drawsuccess="drawimg => drawsuccess(drawimg, i)"
        :class="{ edittemp: !['Image', 'Catalogue'].includes(temp.elementType), edittitlebox: ['Title'].includes(temp.elementType) }"
      ></component>
    </div>

    <drawer
      v-if="drawer"
      :drawer="drawer"
      :tempitem="innerList[activeidx]"
      :catalogueidx="catalogueidx"
      @drawer-change="chart => chartChange(chart, activeidx)"
      @set-change="setChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { TitleTemp, ImageTemp, TextBoxTemp, CatalogueTemp, ChartTemp } from './modules'
import drawer from './drawer'
import Table from '@/components/tables/tableHead'
import extractjs from '../exportPPTjs/extract'
import { getOptionV2 } from '@/script/bookmarkV2/getOptionV2'
import dashbdjs from './modules/dashboard/dashboardjs'
import reportJS from '@/views/Report/report.js'
export default {
  props: ['pageList'],
  components: { TitleTemp, ImageTemp, TextBoxTemp, CatalogueTemp, ChartTemp, drawer },
  data() {
    return {
      copyList: null,
      drawer: false,
      activeidx: '',
      innerList: [],
      catalogueidx: 0,
    }
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  created() {
    this.innerList = this.pageList.elements.map(x => {
      x.itemkey = Math.random()
      return x
    })
    this.copyList = this.$lo_.cloneDeep(this.innerList)
  },
  watch: {
    'basicInfo.project.theme': function() {
      this.themeReset()
    },
    'basicInfo.project.mainColor': function() {
      this.themeReset()
    },
    'basicInfo.project.axisUnit': function() {
      this.themeReset()
    },
  },
  methods: {
    clicktemp(temp, idx) {
      if (this.$commonJs._point('BI_F0036') && ['TextBox', 'Title', 'Chart'].includes(temp.elementType)) {
        this.activeidx = idx
        if (['TextBox', 'Title'].includes(temp.elementType) || (temp.elementType === 'Chart' && !temp.chartdata)) {
          this.drawer = true
        }
      }
    },
    cataloguechange(cataidx, idx) {
      this.activeidx = idx
      this.catalogueidx = cataidx
      this.drawer = true
    },
    chartChange(item, idx) {
      this.drawer = false
      this.$set(this.innerList, idx, item)
      this.$emit('drawer-change', this.innerList)

      // 编辑结束判断当前页面是否有改动
      let ischange = false
      let copypage = this.$lo_.cloneDeep(this.pageList)
      this.innerList.forEach((list, i) => {
        if (list.elementType === 'TextBox') {
          if (list.content.text !== this.copyList[i].content.text) {
            ischange = true
          }
        }
        if (list.elementType === 'Catalogue') {
          if (JSON.stringify(list.content.catalogueList) !== JSON.stringify(this.copyList[i].content.catalogueList)) {
            ischange = true
          }
        }
      })
      if (ischange) {
        this.$eventBus.$emit('pageChangeBus', copypage)
      }
    },
    setChange(item) {
      this.$set(this.innerList, this.activeidx, item)
    },
    edit(idx) {
      this.activeidx = idx
      this.drawer = true
    },
    del(idx) {
      this.$confirm('确定删除该卡片吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        customClass: 'theme_message',
      })
        .then(() => {
          this.$set(this.innerList[idx], 'itemkey', this.innerList[idx].itemkey + 1)
          this.$set(this.innerList[idx], 'chartdata', null)
          this.$set(this.innerList[idx], 'definition', null)
          this.drawsuccess('', idx)
        })
        .catch(() => {})
    },
    tempstyle(temp){
     return extractjs.tempstyle(temp)
    },

    alltimechange(time) {
      this.innerList.forEach((item, idx) => {
        if (item.elementType === 'Chart') {
          let timeRange = item?.chartdata?.timeRange
          if (timeRange) {
            if (item.chartdata.searchCode == 'advance') {
              if (!timeRange) timeRange = []
              timeRange[0] = JSON.parse(JSON.stringify(time))
            } else {
              timeRange[0] = JSON.parse(JSON.stringify(time))
            }
            this.gettimeRange(time, item, idx)
          }
        }
      })
    },
    gettimeRange(time, el, idx) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el.chartdata
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '')
      } else {
        this.$refs['tempref' + idx][0].alltimechange(time)
      }
    },

    themeReset() {
      this.innerList.forEach((item, idx) => {
        if (item.elementType === 'Chart' && item.chartdata) {
          const chartdata = item.chartdata
          const definition = {
            parameter: chartdata.reqObj.parameter,
            settings: chartdata.reqObj.settings,
          }
          let temp =
            chartdata.cardinfo.category == 'template'
              ? chartdata.cardinfo.usage
              : chartdata.cardinfo.category.slice(0, 1).toUpperCase() + chartdata.cardinfo.category.slice(1)
          const opts = {
            ...definition.settings,
            analysisType: temp,
          }
          const tableObj = new Table({
            opts,
            columns: chartdata.rawdata.columns,
            result: chartdata.rawdata.rows,
          })
          let option = getOptionV2(this, definition, chartdata.rawdata, opts, tableObj, '', chartdata)

          const curcolor = dashbdjs.initChartColor(chartdata)
          item.chartdata.chartColorV2 = curcolor
          item.chartdata.option = option
          item.itemkey++
        }
      })
    },
    async drawsuccess(file, idx) {
      let copypage = this.$lo_.cloneDeep(this.pageList)
      if (file) {
        // 根据卡片数据和卡片定义和卡片设置得到 唯一标识md5
        const cardobj = {
          parameter: this.innerList[idx].chartdata.reqObj.parameter,
          settings: this.innerList[idx].chartdata.reqObj.settings,
          carddata: this.innerList[idx].chartdata.orginResult,
        }
        const objblob = new Blob([JSON.stringify(cardobj)])
        const imgmd5 = await reportJS.getMD5(objblob)
        if (!this.innerList[idx].content.imgmd5 || imgmd5 !== this.innerList[idx].content.imgmd5) {
          copypage.elements[idx].content.imgmd5 = imgmd5
          copypage.elements[idx].content.file = file
          this.$eventBus.$emit('pageChangeBus', copypage)
        }
      } else {
        if (!this.innerList[idx].content.imgurl) {
          this.$eventBus.$emit('pageChangeBus', copypage)
        }
      }
    },

    changeCopys() {
      this.copyList = this.$lo_.cloneDeep(this.innerList)
    },
    exportpptData() {
      const copylist = JSON.parse(JSON.stringify(this.innerList))
      const _this = this
      let elements = copylist.map((x, i) => {
        const item = _this.$refs['tempref' + i][0].exportdata()
        return item
      })
      return elements
    },
  },
}
</script>

<style lang="scss" scoped>
.pptItem {
  width: 100%;
  height: 100%;
  position: relative;
  .tempItem {
    position: absolute;
    &:hover {
      .tempset {
        display: block;
      }
      .edittemp {
        @include high_border1($highcolor-cheng);
      }
      .edittitlebox {
        @include high_dashed_border($highcolor-cheng);
      }
    }
    &.selected {
      .edittemp {
        @include high_border1($highcolor-cheng);
      }
      .edittitlebox {
        @include high_dashed_border($highcolor-cheng);
      }
    }
    .edittemp {
      border: 1px dashed rgba(0, 0, 0, 0.2);
    }
    .tempset {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      span {
        color: #adb5bd;
        margin-right: 5px;
        font-size: 14px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    .temp {
      width: 100%;
      height: 100%;
      padding: 1px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0);
      &.imagetemp {
        padding: 0;
        border: 0;
      }
      &.edittemp {
        border: 1px dashed rgba(0, 0, 0, 0.2);
      }
      &.edittitlebox {
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }
}
</style>
