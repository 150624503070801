<template>
  <div class="cataloguetemp">
    <div class="catalogItem dis_flex" v-for="(cata, index) in catalogList" @click="catalogchange(index)" :key="index">
      <span v-text="'0' + (index + 1)"></span>
      {{ cata.text }}
      <i v-if="catalogList.length > 2 && index > 1" @click.stop="delcatalog(index)" class="el-icon-close"></i>
    </div>
    <div v-if="catalogList.length < 6" @click="addcatalog" class="addbtn">+ 添加目录</div>
  </div>
</template>
<script>
export default {
  name: 'Cataloguetemp',
  props: ['tempitem'],
  data() {
    return {
      catalogList: [],
    }
  },
  created() {
    this.catalogList = this.tempitem.content.catalogueList
    this.init()
  },
  methods: {
    init() {},
    addcatalog() {
      const idx = this.catalogList.length
      const list = {
        text: '标题' + (idx + 1),
      }
      this.catalogList.push(list)
    },
    delcatalog(idx) {
      this.catalogList.splice(idx, 1)
    },
    catalogchange(idx) {
      this.$emit('catalogue-change', idx)
    },

    exportdata() {
      let temp = JSON.parse(JSON.stringify(this.tempitem))
      return temp
    },
  },
}
</script>

<style lang="scss" scoped>
.cataloguetemp {
  .catalogItem {
    width: 100%;
    height: 64px;
    line-height: 64px;
    font-size: 36px;
    padding: 0 20px;
    border: 3px solid #ccc;
    margin-bottom: 30px;
    padding-left: 60px;
    text-align: center;
    position: relative;
    span {
      display: block;
      width: 64px;
      height: 64px;
      text-align: center;
      font-size: 45px;
      font-weight: bold;
      line-height: 64px;
      background: #d03a2b;
      color: #fff;
      position: absolute;
      top: -20px;
      left: -35px;
    }
    i {
      position: absolute;
      right: -50px;
      top: 20px;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        @include high_color1($highcolor-cheng);
      }
    }
  }
  .addbtn {
    display: inline-block;
    cursor: pointer;
    @include high_color1($highcolor-cheng);
  }
}
</style>
