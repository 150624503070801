<template>
  <el-drawer
    :title="edititem.elementType === 'Chart' ? '编辑图表' : '编辑文本'"
    :visible.sync="showdrawer"
    :direction="direction"
    :show-close="false"
    @close="closedrawer"
  >
    <div class="drawerbox">
      <div v-if="['TextBox', 'Title', 'Catalogue'].includes(edititem.elementType)" class="text-drawer">
        <h3>文本内容</h3>
        <template v-if="edititem.elementType === 'TextBox'">
          <el-input type="textarea" maxlength="225" :rows="5" placeholder="请输入内容" v-model="edititem.content.text" @input="inputchange" size="small">
          </el-input>
        </template>
        <template v-if="edititem.elementType === 'Title'">
          <h4>标题</h4>
          <el-input
            type="textarea"
            :maxlength="edititem.settings.titleStyle === 'mainandsub' ? 10 : 20"
            :placeholder="edititem.settings.titleStyle === 'mainandsub' ? '不超过10个字' : '不超过20个字'"
            v-model="edititem.content.maintitle"
            @input="inputchange"
            size="small"
          >
          </el-input>
          <template v-if="edititem.settings.titleStyle === 'mainandsub'">
            <h4>副标题</h4>
            <el-input type="textarea" maxlength="20" placeholder="不超过20个字" v-model="edititem.content.subtitle" @input="inputchange" size="small">
            </el-input>
          </template>
        </template>
        <template v-if="edititem.elementType === 'Catalogue'">
          <ul>
            <el-input maxlength="15" placeholder="不超过15个字" v-model="edititem.content.catalogueList[catalogueidx].text" @input="inputchange" size="small">
            </el-input>
          </ul>
        </template>
      </div>
      <div v-if="edititem.elementType === 'Chart'" class="appearSet">
        <data-source :tempitem="edititem" @cardchange="cardchange" />
        <chartset
          v-if="edititem.chartdata && edititem.chartdata.searchCode !== 'combination'"
          :tempitem="edititem"
          @set-change="setchange"
          :key="chartsetkey"
        />
      </div>
      <!-- <div class="btns"> -->
      <!-- <el-button class="cancel" size="small" @click="cancel">取 消</el-button>
        <el-button type="primary" size="small" @click="saveFile">保 存</el-button> -->
      <!-- </div> -->
    </div>
  </el-drawer>
</template>

<script>
import dataSource from './datasource'
import initCard from './initCard'
import chartset from './editset/chartset'

export default {
  props: ['drawer', 'tempitem', 'catalogueidx'],
  data() {
    return {
      showdrawer: '',
      direction: 'rtl',
      copyitem: '',
      edititem: '',
      chartsetkey: 0,
    }
  },
  components: { dataSource, chartset },
  computed: {},
  watch: {
    drawer: function() {
      this.showdrawer = this.drawer
    },
    'tempitem.itemkey': function() {
      // this.chartsetkey++
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.showdrawer = this.drawer
      this.copyitem = JSON.parse(JSON.stringify(this.tempitem))
      this.edititem = JSON.parse(JSON.stringify(this.tempitem))
    },
    cancel() {
      this.edititem = JSON.parse(JSON.stringify(this.copyitem))
      this.$emit('drawer-change', this.edititem)
    },
    closedrawer() {
      this.saveFile()
    },
    saveFile() {
      if (this.edititem.elementType === 'TextBox') {
        const val = this.edititem.content.text.replace(/\n/g, ' ')
        const replacev = this.replaceval(val)
        this.edititem.content.text = replacev
      }
      this.$emit('drawer-change', this.edititem)
    },
    inputchange(v) {
      this.$set(this.edititem, 'itemkey', this.edititem.itemkey + 1)
      this.$emit('set-change', this.edititem)
    },
    replaceval(v) {
      if (v.includes('  ')) {
        v = v.replace(/  /g, ' ')
        return this.replaceval(v)
      } else {
        return v
      }
    },

    initChart() {},
    async cardchange(item) {
      if (item) {
        initCard.init(this, item, 'newcard')
      } else {
        this.$set(this.edititem, 'chartdata', null)
        this.$set(this.edititem, 'definition', null)
        this.$emit('set-change', this.edititem)
      }
    },
    emitchart(chart) {
      this.$set(this.edititem, 'chartdata', chart)
      this.$set(this.edititem, 'definition', chart.definition)
      this.$set(this.edititem, 'itemkey', this.edititem.itemkey + 1)
      this.$emit('set-change', this.edititem)
    },

    setStyleChange() {},
    setchange(item) {
      let chart = this.edititem.chartdata
      if (item) chart.reqObj.settings = item
      const cardinfo = chart.cardinfo
      const defin = JSON.parse(cardinfo.definition)
      const definition = {
        parameter: defin.parameter,
        settings: chart.reqObj.settings,
      }
      const p = defin.parameter
      const temp = cardinfo.category == 'template' ? p.usage : cardinfo.category.slice(0, 1).toUpperCase() + cardinfo.category.slice(1)
      const d = chart.rawdata
      initCard.searchEnd(this, definition, temp, d, chart)
    },
  },
}
</script>

<style lang="scss" scoped>
.drawerbox {
  height: 100%;
  position: relative;
  .text-drawer {
    padding: 0 20px;
    h3 {
      color: #333;
      margin-bottom: 10px;
    }
    h4 {
      margin-bottom: 10px;
    }
    .el-input {
      margin-bottom: 10px;
    }
    .el-textarea {
      margin-bottom: 10px;
    }
  }
  .appearSet {
    max-height: calc(100% - 60px);
    padding: 0 15px;
    overflow: auto;
  }
  .btns {
    width: 100%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: right;
    .el-button {
      border: 0;
      border-radius: 20px;
      &:not(.el-button--primary) {
        @include high_color1($highcolor-cheng);
        @include high_border1($highcolor-cheng);
        background: none;
      }
      &.el-button--primary {
        @include high_bg1($highcolor-cheng);
        @include high_border1($highcolor-cheng);
      }
    }
  }
}
</style>

<style lang="scss">
.el-drawer {
  width: 325px !important;
  .el-drawer__header {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .el-drawer__body {
    height: calc(100% - 80px);
  }
}
</style>
