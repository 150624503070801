<template>
  <div class="texttemp" :class="{ charttext: pageitem.slideType === 'Chart' }">
    <template v-if="pageitem.slideType === 'Chart'">
      <span v-if="tempitem.content.text">{{ tempitem.content.text }}</span>
      <span class="placeholder" v-else>文本框</span>
    </template>
    <!-- <template v-else>{{ tempitem.content.text }}</template> -->
    <template v-else><div class="innerbox" v-html="tempitem.content.text"></div></template>
  </div>
</template>
<script>
export default {
  name: 'TextBoxtemp',
  props: ['pageitem', 'tempitem'],
  data() {
    return {}
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    exportdata() {
      let temp = JSON.parse(JSON.stringify(this.tempitem))
      return temp
    },
  },
}
</script>

<style lang="scss" scoped>
.texttemp {
  font-size: inherit;
  padding: 0 8px !important;
  &.charttext {
    span {
      display: block;
      width: 100%;
      height: 100%;
      word-break: break-all;
      &.placeholder {
        color: #999;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .innerbox {
    height: 100%;
    color: inherit;
    font-size: inherit;
  }
  .el-input {
    height: 100%;
    font-size: inherit;
    ::v-deep input {
      display: block;
      height: 100%;
      color: inherit;
      background: none !important;
      border: none !important;
      cursor: inherit;
    }
  }
  .el-textarea {
    height: 100%;
    font-size: inherit;
    ::v-deep textarea {
      display: block;
      height: 100%;
      color: inherit;
      background: none !important;
      border: none !important;
      cursor: inherit;
    }
  }
}
</style>
