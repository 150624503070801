import drows from './drowChartV2'
import store from '@/store'
import comm from '@/script/chartjs/comm'

export function getOptionV2(_this, query, data, opts, tbdata, hotmapdata, chart) {
  let basicInfo = store.state.basicInfo
  let setting = (query && query.settings) || {}
  let { styleSet, propSet, themeSet } = setting
  const propSetprops = propSet?.props ?? []
  let option = {}
  let labelColor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
  let copyColumns = JSON.parse(JSON.stringify(data.columns))
  let copyRows = JSON.parse(JSON.stringify(data.rows))
  let num_pattern = /^(-?\d+)(\.\d+)?$/
  if (!styleSet.allDimValues) {
    copyColumns = copyColumns.filter(x => x.usage != 'History')
  }

  let allprops = copyColumns.filter(x => {
    return x.usage !== 'Group'
  })
  let groups = copyColumns.filter(x => {
    return x.usage === 'Group'
  })
  // groups.map(x => {
  //   if (x.dataType === 'Boolean') {
  //     copyRows.map(r => {
  //       r[x.alias] = x.title + ':' + r[x.alias]
  //       return r
  //     })
  //   }
  //   return x
  // })

  // copyRows.forEach(x => {
  //   let alias = groups.map(g => x[g.alias])
  //   x.group_key = alias.join(',')
  // })
  //给 copyRows 每个数据增加一个 group_key 代替 sys_gen_key
  copyRows.forEach(x => {
    let other_key = ''
    groups.forEach((g, idx) => {
      if (idx > 0) {
        other_key += x[g.alias] + ','
      }
    })
    other_key = other_key.substr(0, other_key.length - 1)
    x.otherGroupname = other_key
  })

  let measureColumns = allprops.filter(x => {
    return x.usage === 'Measure'
  })

  // 是否有分组
  let hasGroup = groups.length > 0 && groups[0].title != '总体'

  //分组第一项集合  X轴坐标轴
  let firstGroupList = []
  let setArr = new Set()
  //给每一条添加 除去第一项的分组名集合
  let otherGroupList = []
  let setArr2 = new Set()
  let dictionRows = {}
  copyRows.forEach(x => {
    let alias = groups.map(g => x[g.alias])
    x.group_key = alias.join(',')
    if (hasGroup) {
      setArr.add(alias[0])
      const group_key = alias.slice(1).join(',')
      if (group_key) {
        setArr2.add(group_key)
      }
      dictionRows[alias] = x
    } else {
      setArr.add('总体')
      dictionRows['总体'] = x
    }
  })
  firstGroupList = [...setArr]
  otherGroupList = [...setArr2]
  otherGroupList = otherGroupList.length > 0 ? otherGroupList : ['']

  let tails = ['_contrast', '_last', '_circle']
  let contrastLabels = {
    _contrast: '对比',
    _last: '同比',
    _circle: '环比',
  }

  //去掉比率
  let chartprops = allprops.filter(x => {
    return !x.alias.includes('Rate')
  })
  //线图柱图 开启对比率时 对比值是否显示
  if (styleSet.contrastratio) {
    chartprops = allprops
    if (!styleSet.comparisonValue) {
      chartprops = chartprops.filter(x => x.usage !== 'Contrast')
    }
  }
  if (styleSet.allDimValues) {
    chartprops = chartprops.filter(x => x.usage == 'History')
  }

  //修改名称
  // chartprops = chartprops.map(x => {
  //   tails.map(t => {
  //     if (x.alias.includes(t)) {
  //       let old = chartprops.filter(e => {
  //         return e.alias === x.alias.replace(t, '')
  //       })
  //       // x.title = x.title
  //     }
  //   })
  //   return x
  // })
  switch (setting.type) {
    case 'card':
      getCard()
      break
    case 'pie':
      getPie()
      break
    case 'line':
      if (['Retention', 'retention'].includes(opts.analysisType) || (opts.analysisType === 'Template' && query.parameter.usage === 'Retention')) {
        getRetentionLineBar('line')
      } else {
        getLineBar('line')
      }
      break
    case 'bar':
      if (['Retention', 'retention'].includes(opts.analysisType) || (opts.analysisType === 'Template' && query.parameter.usage === 'Retention')) {
        getRetentionLineBar('bar')
      } else {
        getLineBar('bar')
      }
      break
    case 'bar_line':
      getLineBar('bar_line')
      break
    case 'biaxial_graph':
      getBiaxialGraph()
      break
    case 'multiaxialLine':
      getMultiaxialLineBar('line')
      break
    case 'multiaxialBar':
      getMultiaxialLineBar('bar')
      break
    case 'multiaxial2':
      getMultiaxialLineBar2()
      break
    case 'radar':
      getRadar()
      break
    case 'funnel':
      getFunnel()
      break
    case 'scatter':
      getScatter()
      break
    case 'gauge':
      getGauge()
      break
    case 'bubble':
      getBubble()
      break
    case 'relation':
      getRelation()
      break
    case 'map':
      getMap()
      break
    case 'sankey':
      getSankey()
      break
    case 'table':
      getTable()
      break
    case 'table_more':
      gettable_more()
      break
    case 'table_hotmap':
      gettable_hotmap()
      break
    case 'wordcloud':
      getWordCloud()
      break
    case 'treemap':
      getTreemap()
      break
    case 'map3d':
      getMap3d()
      break
  }

  function sortNumber(a, b) {
    return a - b
  }
  function median(arr) {
    arr.sort(sortNumber)
    //求中位数
    if (arr.length % 2 == 0) {
      return (arr[arr.length / 2 - 1] + arr[arr.length / 2]) / 2
    } else {
      return arr[Math.floor(arr.length / 2)]
    }
  }
  function getCard() {
    const meaalias = measureColumns[0].alias
    const groupalias = groups.length > 0 ? groups[0].alias : ''
    let columns = copyColumns
    let rows0 = copyRows[0]
    option.value = ''
    option.numberFontSize = styleSet ? styleSet.numberFontSize : 'auto'
    option.unitName = styleSet ? styleSet.unitName || '' : ''
    option.measureName = styleSet ? (styleSet.measureName = styleSet.measureName || measureColumns[0].title) : ''
    option.showMeasure = styleSet ? styleSet.showMeasure : ''
    option.measureNameFontSize = styleSet ? styleSet.measureNameFontSize : ''
    option.cardConrastdata = []
    option.value = numUnit(rows0[meaalias])

    let rateColumns = columns.filter(x => {
      return x.usage == 'Rate'
    })
    option.cardConrastdata = rateColumns.map(c => {
      let tit = ''
      if (c.alias.includes('_contrastRate')) {
        tit = '对比'
      } else if (c.alias.includes('_lastRate')) {
        tit = '同比'
      } else if (c.alias.includes('_circleRate')) {
        tit = '环比'
      }
      return {
        title: tit,
        value: rows0[c.alias],
      }
    })
    option.arr = copyRows.map(x => {
      let list = {
        label: groupalias ? x[groupalias] : 'g0',
        value: numUnit(x[meaalias]),
      }
      list.cardConrastdata = rateColumns.map(c => {
        let tit = ''
        if (c.alias.includes('_contrastRate')) {
          tit = '对比'
        } else if (c.alias.includes('_lastRate')) {
          tit = '同比'
        } else if (c.alias.includes('_circleRate')) {
          tit = '环比'
        }
        return {
          title: tit,
          value: x[c.alias],
        }
      })
      return list
    })
  }

  function getPie() {
    let config = {
      legend: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      propfmt: null,
      displayType: allprops[0].displayType,
    }
    const prp = propSetprops && propSetprops[0]
    config.propfmt = prp?.format ?? null
    if (typeof styleSet.showNumber == 'undefined') {
      styleSet.showNumber = true
    }
    if (typeof styleSet.enablePencentage == 'undefined') {
      styleSet.enablePencentage = true
    }

    if (opts.analysisType == 'Scatter') {
      var pieAlias = allprops
        .filter(x => x.alias != 'total')
        .map(x => {
          return { alias: x.alias, title: x.title }
        })

      config.legend = allprops
        .filter(x => x.alias != 'total')
        .map(x => {
          return x.title
        })

      pieAlias.forEach(x => {
        var series = {
          name: x.title,
          value: copyRows ? copyRows[0][x.alias] : '',
        }
        config.seriesdata.push(series)
      })
    } else {
      config.legend = hasGroup ? firstGroupList : ['总体']
      config.legend = config.legend.length > 0 ? config.legend : allprops[0].title
      let ser = {}
      copyRows.forEach(x => {
        ser = {
          name: x.group_key ? x.group_key : allprops[0].title,
          value: x[allprops[0].alias],
        }
        config.seriesdata.push(ser)
      })
    }

    option = drows.drowPieV2(_this, config)
  }
  function getLineBar(isline) {
    let config = {
      isline: isline,
      legend: [],
      seriesdata: [],
      xAxisdata: [],
      yAxis: [{ type: 'value' }],
      setting: setting,
      styleSet: styleSet,
      max: '',
      max_w: '',
      markLineNum: '',
      relacolors: [],
      linetype: isline,
    }
    let ishor = styleSet.direction ? styleSet.direction == 'horizontal' : styleSet.enableHorizontal
    let isHorizontal = ishor && isline == 'bar' ? true : false //是否横向
    let isStack = styleSet.enableStack //是否堆积
    let areaVal = 0
    let smooth = ['areaCurve', 'curve'].includes(styleSet.type) || ['areaCurve', 'curve'].includes(styleSet.lineType) ? true : false
    firstGroupList = isHorizontal ? firstGroupList.reverse() : firstGroupList
    copyRows = isHorizontal ? copyRows.reverse() : copyRows

    if (styleSet.showYAxisTitle) {
      config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[0].title
      // config.yAxisname = styleSet.yAxisUnit ? config.yAxisname + '(' + styleSet.yAxisUnit + ')' : config.yAxisname
    }
    if (styleSet.showXAxisTitle) {
      config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : groups && groups.length > 0 ? groups[0].title : ''
    }
    if (styleSet.direction == 'horizontal') {
      var x = config.yAxisname
      config.yAxisname = config.xAxisname
      config.xAxisname = x
    } else {
      //竖向获取最大值
      let maxNum = 0
      chartprops.forEach(c => {
        copyRows.forEach(row => {
          const num = Math.abs(parseInt(row[c.alias]))
          if (maxNum < num) {
            maxNum = num
          }
        })
      })
      config.max = contrastMax(styleSet, maxNum)
      // let maxval = _this.$commonJs.formatnum(basicInfo.project.axisUnit, config.max)
      let maxval = _this.$commonJs.processnumunit(config.max, setting, false, styleSet.yAxisformat)
      config.max_w = _this.$commonJs.getWordsWidth(maxval)
    }
    let areaValtype = styleSet.type || styleSet.lineType
    switch (areaValtype) {
      case 'line':
        areaVal = 0
        break
      case 'areaLine':
        areaVal = 1
        break
      case 'curve':
        areaVal = 0
        break
      case 'areaCurve':
        areaVal = 1
        break
    }
    let numArr = []
    copyRows.map(c => {
      chartprops.map(p => {
        if (typeof c[p.alias] == 'number') {
          numArr.push(Math.abs(c[p.alias]))
        }
      })
    })
    numArr = pushNumArr(styleSet, numArr)
    config.max = Math.max.apply(null, numArr)
    config.xAxisdata = hasGroup ? firstGroupList : ['总体']
    //关联分析 柱图特殊处理
    if (opts.analysisType == 'Association') {
      let filtercolumns = opts.filterSet.columns.filter(x => {
        return x.selected
      })
      filtercolumns.map(x => {
        config.legend.push(x.text)
        let ser = {
          name: x.text,
          type: isline,
          barMaxWidth: 30,
          label: {
            show: styleSet.enableLable,
            position: isStack ? '' : isHorizontal ? 'right' : 'top',
            color: labelColor,
            formatter: function(v) {
              return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.data)
            },
          },
          data: [],
          code: x.code,
          displayName: x.text,
          measure: x.text,
        }
        copyRows.forEach(d => {
          ser.data.push(d[x.code])
        })
        config.seriesdata.push(ser)
      })
    } //留存 特殊处理
    else if (opts.analysisType == 'Retention') {
      let durationList = []
      durationList = chartprops.filter(x => {
        return x.alias != 'person_number_all'
      })
      chartprops = chartprops.filter(x => {
        return x.alias == 'person_number_all'
      })
      let xAxisList = durationList.map(x => {
        return x.title
      })
      config.xAxisdata = hasGroup ? xAxisList : ['总体']
      otherGroupList.map(g => {
        chartprops.map(e => {
          let leg = g ? g + '-' + e.title : e.title
          let ser = {
            name: leg,
            type: isline,
            lineStyle: {
              type: getLineStyle(e),
            },
            barMaxWidth: 30,
            data: [],
            stack: isStack ? `堆积-${e.title}` : '',
            label: {
              show: styleSet.enableLable,
              position: isStack ? '' : isHorizontal ? 'right' : 'top',
              color: labelColor,
              formatter: function(v) {
                return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.data)
              },
            },
            smooth: smooth,
            areaStyle: {
              opacity: areaVal,
            },
            code: getMeasureCode(e),
            displayName: (g ? g : e.title) + getMeasureTail(e),
            measure: g ? getMeasureName(e) : '',
          }
          if (firstGroupList.length > 0) {
            firstGroupList.map(xix => {
              let sult = copyRows.find(r => {
                return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
              })
              //兼容留存分析
              config.legend.push(xix)
              let newser = JSON.parse(JSON.stringify(ser))
              newser.name = xix
              durationList.forEach(dur => {
                let duralias = dur.alias
                let durval = sult[duralias]
                //留存率
                if (styleSet.enableRate) {
                  let aliasarr = dur.alias.split('_')
                  duralias = 'rate_' + aliasarr[aliasarr.length - 1]
                  durval = sult[duralias]
                  durval = durval ? parseFloat(durval.replace('%', '')) : 0

                  newser.label.formatter = function(v) {
                    return v.data.toFixed(2) + '%'
                  }
                }
                let val = sult ? durval || 0 : 0
                newser.data.push(val)
              })
              config.seriesdata.push(newser)
            })
          } else {
            let val = copyRows[0] ? copyRows[0][e.alias] : 0
            ser.data.push(val)
            config.seriesdata.push(ser)
          }
        })
      })
    } else {
      otherGroupList.forEach((g, gidx) => {
        chartprops.forEach(e => {
          // 'bar_line' 是 柱图+线图 图表
          let lineType = isline == 'bar_line' ? (styleSet.lineprops.includes(e.alias) ? 'line' : 'bar') : isline
          lineType = e.alias.includes('Rate') ? 'line' : lineType
          let leg = g ? (e.title && e.title != ' ' ? g + '-' + e.title : g) : e.title
          config.legend.push(leg)

          //指标的 propset
          const prp = propSetprops.find(p => p.fullAlias === e.fullAlias || e.alias.includes(p.alias + '_'))
          const fmt = prp?.format ?? null
          let ser = {
            name: leg,
            type: lineType,
            lineStyle: {
              type: getLineStyle(e),
            },
            barMaxWidth: 30,
            data: [],
            yAxisIndex: e.alias.includes('Rate') ? 1 : 0,
            stack: isStack && lineType === 'bar' ? `堆积-${e.title}` : '',
            label: {
              show: styleSet.enableLable,
              position: isStack && lineType === 'bar' ? '' : isHorizontal ? 'right' : 'top',
              color: labelColor,
              formatter: function(v) {
                if (styleSet.contrastratio && e.alias.includes('Rate')) {
                  return _this.$commonJs.formatDisplay2(v.data, 'PERCENTAGE_DIRECT', fmt)
                } else {
                  // return _this.$commonJs.propnumformat(store.state.pageunit, v.data, styleSet.enablePencentage, e.displayType, fmt)
                  return _this.$commonJs.processnumunit(v.data, setting, e.displayType, fmt)
                }
              },
            },
            smooth: smooth,
            areaStyle: {
              opacity: areaVal,
            },
            code: getMeasureCode(e),
            displayName: g ? g : e.title,
            // displayName: (g ? g : e.title) + getMeasureTail(e),
            measure: g ? getMeasureName(e) : '',
            displayType: e.displayType,
            alias: e.alias,
          }
          if (firstGroupList.length > 0) {
            firstGroupList.forEach((firstGroup, firstgidx) => {
              let group_key = [firstGroup, g].filter(x => x).join(',')
              let sult = dictionRows[group_key]
              let val = sult ? sult[e.alias] : 0
              let Intval = typeof val === 'number' ? val : val == '-' ? val : parseFloat(val.replace(/[,]/g, ''))
              if (gidx > 0 && lineType === 'line' && styleSet.enableStack && !styleSet.enablePencentage) {
                Intval = Intval == '-' ? config.seriesdata[gidx - 1][firstgidx] : config.seriesdata[gidx - 1].data[firstgidx] + Intval
              }
              ser.data.push(Intval)
            })
          } else {
            let val = copyRows[0] ? copyRows[0][e.alias] : 0
            const Intval = typeof val === 'number' ? val : val == '-' ? val : parseFloat(val.replace(/[,]/g, ''))
            ser.data.push(Intval)
          }
          if (lineType == 'line') {
            ser.symbolSize = styleSet.showDataPoint || e.alias.includes('Rate') ? 8 : 2
          } else {
            ser.showSymbol = false
          }
          //线的颜色
          if (areaVal) {
            let serLen = config.seriesdata.length
            if (config.relacolors.length <= serLen) {
              config.relacolors = config.relacolors.concat(chart.chartColorV2)
            }
            let serColor = config.relacolors[serLen]
            ser.areaStyle.color = comm.getAreaStyle(_this, basicInfo, themeSet, serColor)
          }
          config.seriesdata.push(ser)
        })
      })
    }
    if (styleSet.enablePencentage) {
      var seriesdatas = _.groupBy(config.seriesdata, 'stack')
      Object.values(seriesdatas).forEach(series => {
        var datas = series.filter(x => !x.isAggregation).map(x => x.data)
        var zipped = _.zip(...datas)
        series.forEach(s => {
          s.data = s.data.filter(t => t != undefined)
          for (var i = 0; i < s.data.length; i++) {
            const filternum = zipped[i].filter(nm => typeof nm == 'number')
            var sum = _.sum(filternum)
            if (sum == 0) {
              s.data[i] = 0
            } else {
              s.data[i] = (s.data[i] * 100) / sum
            }
            s.data[i] = s.data[i].toFixed(2)
          }
        })
      })
    }
    //辅助线
    _this.$commonJs2.initMarkLine(_this, chart.chartColorV2, config, copyRows, contrastLabels, labelColor)
    option = drows.drowLineV2(_this, config, propSetprops)
  }

  function getBiaxialGraph() {
    let config = {
      legend: [],
      seriesdata: [],
      xAxisdata: [],
      yAxis: [],
      setting: setting,
      styleSet: styleSet,
      max: '',
      max_w: '',
      markLineNum: '',
      relacolors: [],
      maxarrlen: [], //多轴图每个轴的 单位最长的集合
    }
    if (styleSet.showYAxisTitle) {
      config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[0].title
    }
    if (styleSet.showXAxisTitle) {
      config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : groups && groups.length > 0 ? groups[0].title : ''
    }
    let areaVal = 0
    switch (styleSet.lineType) {
      case 'line':
        areaVal = 0
        break
      case 'areaLine':
        areaVal = 1
        break
      case 'curve':
        areaVal = 0
        break
      case 'areaCurve':
        areaVal = 1
        break
    }
    let nextareaVal = 0
    switch (styleSet.nextLineType) {
      case 'line':
        nextareaVal = 0
        break
      case 'areaLine':
        nextareaVal = 1
        break
      case 'curve':
        nextareaVal = 0
        break
      case 'areaCurve':
        nextareaVal = 1
        break
    }

    //竖向获取 绝对值最大值
    let maxNum = 0
    chartprops.forEach(c => {
      copyRows.forEach(row => {
        var num = Math.abs(row[c.alias])
        if (maxNum < num) {
          maxNum = num
        }
      })
    })
    config.max = contrastMax(styleSet, maxNum)

    let maxval = _this.$commonJs.formatnum(store.state.pageunit, config.max)
    config.max_w = _this.$commonJs.getWordsWidth(maxval)
    //是否柱图+线图
    otherGroupList.forEach((g, gidx) => {
      chartprops.forEach((e, ci) => {
        // 'bar_line' 是 柱图+线图 图表
        const isnext = styleSet.lineprops.includes(e.alias)
        let lineType = isnext ? (styleSet.lineType === 'bar' ? 'bar' : 'line') : styleSet.nextLineType === 'bar' ? 'bar' : 'line'
        let leg = g ? (e.title && e.title != ' ' ? `${g}-${e.title}` : g) : e.title
        config.legend.push(leg)
        const prp = propSetprops.find(p => p.fullAlias === e.fullAlias || e.alias.includes(p.alias + '_'))
        const fmt = prp?.format ?? null
        let series = {
          contrastType: e.contrastType,
          name: leg,
          type: lineType,
          lineStyle: {
            type: getLineStyle(e),
          },
          barMaxWidth: 30,
          data: [],
          yAxisIndex: isnext ? 0 : 1,
          stack: styleSet.enableStack && lineType === 'bar' ? `堆积-${e.title}` : '',
          label: {
            show: styleSet.enableLable,
            position: styleSet.enableStack ? '' : styleSet.direction == 'horizontal' ? 'right' : 'top',
            color: labelColor,
            formatter: function(v) {
              // return _this.$commonJs.propnumformat(store.state.pageunit, v.data, styleSet.enablePencentage, e.displayType, fmt)
              return _this.$commonJs.processnumunit(v.data, setting, e.displayType, fmt)
            },
          },
          smooth: isnext ? ['areaCurve', 'curve'].includes(styleSet.lineType) : ['areaCurve', 'curve'].includes(styleSet.nextLineType),
          areaStyle: {
            opacity: isnext ? areaVal : nextareaVal,
          },
          code: getMeasureCode(e),
          displayName: g ? g : e.title,
          measure: g ? getMeasureName(e) : '',
          displayType: e.displayType,
          alias: e.alias,
        }
        if (firstGroupList.length > 0) {
          firstGroupList.forEach(firstGroup => {
            let group_key = [firstGroup, g].filter(x => x).join(',')
            let r = dictionRows[group_key]
            let val = r && r[e.alias] ? r[e.alias] : 0
            series.data.push(val)
          })
        } else {
          let val = copyRows.length > 0 ? copyRows[0][e.alias] : 0
          series.data.push(val)
        }
        if (lineType == 'line') {
          const point = isnext ? styleSet.showDataPoint : styleSet.shownextDataPoint
          series.symbolSize = point ? 8 : 2
        } else {
          series.showSymbol = false
        }
        //线的颜色
        if (isnext ? areaVal : nextareaVal) {
          let serLen = config.seriesdata.length
          if (config.relacolors.length <= serLen) {
            config.relacolors = config.relacolors.concat(chart.chartColorV2)
          }
          let serColor = config.relacolors[serLen]
          series.lineStyle.color = serColor
          series.areaStyle.color = comm.getAreaStyle(_this, basicInfo, themeSet, serColor)
        }

        //辅助线
        if ((styleSet.subline == 'measureField' && styleSet.sublineField == e.alias) || (styleSet.subline == 'fixed' && ci == 0)) {
          series.markLine = {
            silent: false,
            data: [],
          }
          const markLineItem = getmarkLineItem(series)
          if (markLineItem) {
            series.markLine.data.push(markLineItem)
            series.markLine.silent = true
          }
        }

        config.seriesdata.push(series)
      })
    })

    function getmarkLineItem(series) {
      var markLineDataItem = {
        label: {
          show: true,
          position: 'end',
          color: labelColor,
          formatter: function(v) {
            return _this.$commonJs.formatnum(store.state.pageunit, v.value, styleSet.enablePencentage)
          },
        },
        lineStyle: {
          normal: {
            color: chart.chartColorV2[0],
            width: 1,
            type: 'dashed',
          },
        },
      }

      if (series && styleSet.subline == 'fixed' && num_pattern.test(styleSet.sublineVal)) {
        markLineDataItem.name = '固定值'
        markLineDataItem.yAxis = styleSet.sublineVal
      }
      if (series && styleSet.subline == 'measureField') {
        var values = copyRows.map(x => x[styleSet.sublineField])
        var num = 0
        var name = ''
        if (styleSet.sublineAggregation == 'max') {
          num = _.max(values)
          name = '最大值'
        } else if (styleSet.sublineAggregation == 'min') {
          num = _.min(values)
          name = '最小值'
        } else {
          if (values.length > 0) {
            num = _.sum(values) / values.length
          }
          name = '平均值'
        }
        markLineDataItem.name = name
        markLineDataItem.yAxis = num
      }
      return markLineDataItem.yAxis ? markLineDataItem : null
    }

    if (styleSet.summaryLine && styleSet.summaryLine != 'none') {
      let seriesdatas = _.groupBy(config.seriesdata, 'contrastType')
      Object.values(seriesdatas).forEach(series => {
        let xx = _.groupBy(series, 'measure')
        Object.values(xx).forEach(yy => {
          var data = []
          var datas = yy.map(x => x.data)
          var zipped = _.zip(...datas)
          zipped.forEach(z => {
            z = z.filter(d => d != undefined)
            var num = _.sum(z)
            if (styleSet.summaryLine == 'avg') {
              num = Math.round((num / (z.length || 1)) * 100) / 100
            }
            data.push(num)
          })
          var newSeries = _.cloneDeep(yy[0])
          newSeries.data = data
          newSeries.isAggregation = true //聚合数据
          newSeries.displayName = styleSet.summaryLine == 'sum' ? `总计` : '平均值'
          newSeries.displayName = (contrastLabels[newSeries.contrastType] || '') + newSeries.displayName
          newSeries.measure = yy[0].measure
          newSeries.name = `${newSeries.displayName}-${newSeries.measure}`
          config.seriesdata.push(newSeries)
          config.legend.push(newSeries.name)
        })
      })
    }
    if (styleSet.enablePencentage) {
      var seriesdatas = _.groupBy(config.seriesdata, 'stack')
      Object.values(seriesdatas).forEach(series => {
        var datas = series.filter(x => !x.isAggregation).map(x => x.data)
        var zipped = _.zip(...datas)
        series.forEach(s => {
          s.data = s.data.filter(t => t != undefined)
          for (var i = 0; i < s.data.length; i++) {
            var sum = _.sum(zipped[i])
            if (sum == 0) {
              s.data[i] = 0
            } else {
              s.data[i] = (s.data[i] * 100) / sum
            }
            s.data[i] = s.data[i].toFixed(2)
          }
        })
      })
    }
    config.xAxisdata = hasGroup ? firstGroupList : ['总体']

    //双轴图坐标轴
    let BiaxialList = [[], []]
    measureColumns.forEach(x => {
      if (styleSet.lineprops.includes(x.alias)) {
        BiaxialList[0].push(x)
      } else {
        BiaxialList[1].push(x)
      }
    })

    BiaxialList.forEach((x, i) => {
      const titles = x.map(t => t.title)
      let max = 0
      let numArr = []
      copyRows.forEach(c => {
        chartprops.forEach(p => {
          if (titles.includes(p.title)) {
            numArr.push(Math.abs(c[p.alias]))
          }
        })
      })
      numArr = pushNumArr(styleSet, numArr)
      max = Math.max.apply(null, numArr)
      const fmtmax = _this.$commonJs.processnumunit(max, setting, false, styleSet.yAxisformat)
      let max_w = _this.$commonJs.getWordsWidth(fmtmax)
      config.maxarrlen.push(max_w)
      let yAx = {
        show: i % 2 == 0 ? styleSet.showYAxis && styleSet.lineprops.length > 0 : styleSet.shownextYAxis && styleSet.nextLineprops.length > 0,
        type: 'value',
        name: i % 2 == 0 ? styleSet.yAxisTitle : styleSet.nextYAxisTitle,
        nameTextStyle: {
          color: labelColor,
          align: 'left',
          padding: [0, 0, 0, -28],
        },
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: max_w,
        position: i % 2 == 0 ? 'left' : 'right',
        offset: _this.$commonJs.getoffset(i, config.maxarrlen),
        axisLabel: {
          color: labelColor,
          formatter: function(v) {
            return _this.$commonJs.processAxisunit(max, v, setting)
          },
        },
        axisLine: {
          show: i % 2 == 0 ? styleSet.yAxisLine : styleSet.nextYAxisLine,
          lineStyle: {
            color: basicInfo.project.theme == 0 ? '#E9ECEF' : '#555',
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: ['allLine', 'horizontalLine'].includes(styleSet.gridLine),
          lineStyle: {
            type: 'dotted',
            color: basicInfo.project.theme == 0 ? '#E9ECEF' : '#555',
          },
        },
      }
      var num_pattern = /^(-?\d+)(\.\d+)?$/
      if (styleSet.showYAxis && i % 2 == 0) {
        const areaYAxis = styleSet.areaYAxis
        if (num_pattern.test(areaYAxis[0])) yAx.min = areaYAxis[0]
        if (num_pattern.test(areaYAxis[1])) yAx.max = areaYAxis[1] > areaYAxis[0] ? areaYAxis[1] : null
      }
      if (styleSet.shownextYAxis && i % 2 == 1) {
        const areaYAxis = styleSet.nextareaYAxis
        if (num_pattern.test(areaYAxis[0])) yAx.min = areaYAxis[0]
        if (num_pattern.test(areaYAxis[1])) yAx.max = areaYAxis[1] > areaYAxis[0] ? areaYAxis[1] : null
      }
      config.yAxis.push(yAx)
    })
    //单位
    BiaxialList.forEach((x, i) => {
      const unityAx = {
        name: i % 2 == 0 ? styleSet.yAxisUnit : styleSet.nextYAxisUnit,
        nameLocation: 'end',
        position: i % 2 == 0 ? 'left' : 'right',
        nameGap: 15,
        nameTextStyle: {
          color: labelColor,
          align: i % 2 == 0 ? 'right' : 'left',
        },
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      }
      config.yAxis.push(unityAx)
    })

    option = drows.drowMultiaxialV2(_this, config, propSetprops)
  }

  function getMultiaxialLineBar(isline) {
    let config = {
      legend: [],
      xAxisdata: [],
      yAxis: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      maxarrlen: [], //多轴图每个轴的 单位最长的集合
    }
    config.xAxisdata = hasGroup ? firstGroupList : ['总体']

    otherGroupList.map(g => {
      chartprops.map(e => {
        let leg = g ? (e.title && e.title != ' ' ? `${g}-${e.title}` : g) : e.title
        config.legend.push(leg)
        let yAxisIndex = 0

        measureColumns.forEach((y, yidx) => {
          yAxisIndex = e.alias.includes(y.alias) ? yidx : yAxisIndex
        })

        const prp = propSetprops.find(p => p.fullAlias === e.fullAlias || e.alias.includes(p.alias + '_'))
        const fmt = prp?.format ?? null
        let ser = {
          name: leg,
          type: isline,
          lineStyle: {
            type: getLineStyle(e),
          },
          barMaxWidth: 30,
          data: [],
          yAxisIndex: yAxisIndex,
          label: {
            show: styleSet.enableLable,
            color: labelColor,
            position: 'top',
            formatter: function(v) {
              // return _this.$commonJs.propnumformat(basicInfo.project.axisUnit, v.value, false, e.displayType, fmt)
              return _this.$commonJs.processnumunit(v.data, setting, e.displayType, fmt)
            },
          },

          code: getMeasureCode(e),
          displayName: (g ? g : e.title) + getMeasureTail(e),
          measure: g ? getMeasureName(e) : '',
          displayType: e.displayType,
          alias: e.alias,
        }
        if (!hasGroup) {
          let val = copyRows[0] ? copyRows[0][e.alias] : 0
          ser.data.push(val)
        } else {
          config.xAxisdata.map(xix => {
            let sult = copyRows.filter(r => {
              // return xix == r[groups[0].alias] && r.otherGroupname == g
              return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
              // return g ? xix == r[groups[0].alias] && (r[groups[0].code] == g || r[groups[1].code] == g || r[groups[1].code+'_dim'] == g) : xix == r[groups[0].alias]
            })
            let val = sult[0] ? sult[0][e.alias] : 0
            ser.data.push(val)
          })
        }
        config.seriesdata.push(ser)
      })
    })
    measureColumns.map((x, i) => {
      let max = 0
      let numArr = []
      copyRows.map(c => {
        chartprops.map(p => {
          if (p.title.includes(x.title)) {
            numArr.push(Math.abs(c[p.alias]))
          }
        })
      })
      numArr = pushNumArr(styleSet, numArr)
      max = Math.max.apply(null, numArr)
      let max_w = _this.$commonJs.getWordsWidth(_this.$commonJs.formatnum(basicInfo.project.axisUnit, max))

      config.maxarrlen.push(max_w)
      let yAx = {
        type: 'value',
        name: x.title,
        // min:0,
        // max:max,
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: max_w,
        position: i % 2 == 0 ? 'left' : 'right',
        offset: _this.$commonJs.getoffset(i, config.maxarrlen),
        nameTextStyle: {
          color: labelColor,
        },
        axisLabel: {
          color: labelColor,
          formatter: function(v) {
            return _this.$commonJs.formatAxis(basicInfo.project.axisUnit, max, v)
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: ['allLine', 'horizontalLine'].includes(styleSet.gridLine),
          lineStyle: {
            type: 'dotted',
            color: basicInfo.project.theme == 0 ? '#E9ECEF' : '#555',
          },
        },
      }
      config.yAxis.push(yAx)
    })
    option = drows.drowMultiaxialV2(_this, config)
  }
  function getMultiaxialLineBar2() {
    let config = {
      legend: [],
      xAxisdata: [],
      yAxis: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      maxarrlen: [], //多轴图每个轴的 单位最长的集合
    }

    let contrastSetting = query[0].displaySettingGroup

    config.xAxisdata = hasGroup ? firstGroupList : ['总体']

    let isTwo = measureColumns.length == 1 ? chartprops : measureColumns

    otherGroupList.map(g => {
      chartprops.map((e, eidx) => {
        let sett = eidx % 2 == 0 ? contrastSetting.left : contrastSetting.right
        let leg = g ? (e.title && e.title != ' ' ? `${g}-${e.title}` : g) : e.title
        config.legend.push(leg)
        let yAxisIndex = 0

        isTwo.forEach((y, yidx) => {
          yAxisIndex = e.alias.includes(y.alias) ? yidx : yAxisIndex
        })
        let ser = {
          name: leg,
          type: sett.type == 'areaCurve' || sett.type == 'areaLine' ? 'line' : sett.type,
          lineStyle: {
            type: getLineStyle(e),
          },
          barMaxWidth: 30,
          data: [],
          yAxisIndex: contrastSetting.left.axisNum == 'single' ? '0' : yAxisIndex,
          label: {
            show: sett.enableLable,
            color: labelColor,
            position: 'top',
            formatter: function(v) {
              return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
            },
          },

          code: getMeasureCode(e),
          displayName: g ? g : e.title,
          measure: g ? getMeasureName(e) : '',
        }
        if (!hasGroup) {
          let val = copyRows[0] ? copyRows[0][e.alias] : 0
          ser.data.push(val)
        } else {
          config.xAxisdata.map(xix => {
            let sult = copyRows.filter(r => {
              // return xix == r[groups[0].alias] && r.otherGroupname == g
              return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
            })
            let val = sult[0] ? sult[0][e.alias] : 0
            ser.data.push(val)
          })
        }
        config.seriesdata.push(ser)
      })
    })

    //兼容单轴图的最大值
    let allnumArr = []
    if (contrastSetting.left.axisNum == 'single') {
      isTwo.forEach(x => {
        copyRows.forEach(c => {
          chartprops.map(p => {
            if (p.title.includes(x.title)) {
              allnumArr.push(Math.abs(c[p.alias]))
            }
          })
        })
      })
    }

    isTwo.map((x, i) => {
      let max = 0
      let numArr = []
      copyRows.map(c => {
        chartprops.map(p => {
          if (p.title.includes(x.title)) {
            numArr.push(Math.abs(c[p.alias]))
          }
        })
      })
      max = contrastSetting.left.axisNum == 'single' ? Math.max.apply(null, allnumArr) : Math.max.apply(null, numArr)
      let max_w = _this.$commonJs.getWordsWidth(_this.$commonJs.formatnum(basicInfo.project.axisUnit, max))
      max_w = max_w < 35 ? max_w + 10 : max_w

      config.maxarrlen.push(max_w)

      let yAx = {
        type: 'value',
        name: x.title,
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: max_w,
        position: i % 2 == 0 ? 'left' : 'right',
        offset: _this.$commonJs.getoffset(i, config.maxarrlen),
        nameTextStyle: {
          color: labelColor,
        },
        axisLabel: {
          color: labelColor,
          formatter: function(v) {
            return _this.$commonJs.processAxisunit(max, v, setting)
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: ['allLine', 'horizontalLine'].includes(styleSet.gridLine),
          lineStyle: {
            type: 'dotted',
            color: basicInfo.project.theme == 0 ? '#E9ECEF' : '#555',
          },
        },
      }
      config.yAxis.push(yAx)
    })
    if (contrastSetting.left.axisNum == 'single') {
      config.yAxis.splice(1, 1)
      config.maxarrlen[1] = 0
    }
    option = drows.drowMultiaxialV2(_this, config, propSetprops)
  }
  function getRetentionLineBar(isline) {
    let config = {
      isline: isline,
      legend: [],
      seriesdata: [],
      xAxisdata: [],
      yAxis: [{ type: 'value' }],
      setting: setting,
      styleSet: styleSet,
      max: '',
      max_w: '',
      markLineNum: '',
      relacolors: [],
    }
    let ishor = styleSet.direction ? styleSet.direction == 'horizontal' : styleSet.enableHorizontal
    let isHorizontal = ishor && isline == 'bar' ? true : false //是否横向
    let isStack = styleSet.enableStack && isline === 'bar' //是否堆积
    let areaVal = 0
    let smooth = styleSet.type == 'areaCurve' || styleSet.type == 'curve' || styleSet.lineType == 'areaCurve' || styleSet.lineType == 'curve' ? true : false
    if (isHorizontal) {
      if (query.parameter.paging && query.parameter.paging.direction) {
        firstGroupList.reverse()
      }
    }
    copyRows = isHorizontal ? copyRows.reverse() : copyRows

    if (styleSet.showYAxisTitle) {
      config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[0].title
    }
    if (styleSet.showXAxisTitle) {
      config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : groups && groups.length > 0 ? groups[0].title : ''
    }
    if (styleSet.direction == 'horizontal') {
      var x = config.yAxisname
      config.yAxisname = config.xAxisname
      config.xAxisname = x
    } else {
      //竖向获取最大值
      let maxNum = 0
      chartprops.forEach(c => {
        copyRows.forEach(row => {
          var num = Math.abs(row[c.alias])
          if (maxNum < num) {
            maxNum = num
          }
        })
      })
      config.max = contrastMax(styleSet, maxNum)
      let maxval = _this.$commonJs.formatnum(basicInfo.project.axisUnit, config.max)
      config.max_w = _this.$commonJs.getWordsWidth(maxval)
    }
    let areaValtype = styleSet.type || styleSet.lineType
    switch (areaValtype) {
      case 'line':
        areaVal = 0
        break
      case 'areaLine':
        areaVal = 1
        break
      case 'curve':
        areaVal = 0
        break
      case 'areaCurve':
        areaVal = 1
        break
    }
    let numArr = []
    copyRows.map(c => {
      chartprops.map(p => {
        if (typeof c[p.alias] == 'number') {
          numArr.push(Math.abs(c[p.alias]))
        }
      })
    })
    numArr = pushNumArr(styleSet, numArr)
    config.max = Math.max.apply(null, numArr)

    const retentionProps =
      chartprops.length > 1
        ? chartprops.filter(x => {
            return x.alias !== 'total'
          })
        : chartprops

    let relagroups = []
    if (groups.length > 1) {
      firstGroupList.forEach(x => {
        otherGroupList.forEach(k => {
          const le = x + ',' + k
          relagroups.push(le)
        })
      })
    } else {
      relagroups = hasGroup ? firstGroupList : ['总体']
    }
    relagroups.forEach(f => {
      config.legend.push(f)
      let ser = {
        name: f,
        type: isline,
        lineStyle: {
          type: 'solid',
        },
        barMaxWidth: 30,
        data: [],
        stack: isStack ? `堆积` : '',
        label: {
          show: styleSet.enableLable,
          position: isStack ? '' : isHorizontal ? 'right' : 'top',
          color: labelColor,
          formatter: function(v) {
            return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.data, styleSet.enablePencentage, retentionProps[0].displayType)
          },
        },
        smooth: smooth,
        areaStyle: {
          opacity: areaVal,
        },
        code: getMeasureCode(retentionProps[0]),
        displayName: f,
        measure: '',
        alias: retentionProps[0].alias,
      }
      if (retentionProps.length > 0) {
        retentionProps.forEach(r => {
          const row = dictionRows[f]
          const val = row && row[r.alias] ? row[r.alias] : 0
          ser.data.push(val)
        })
        // let sult = copyRows.find(r => {
        //   return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
        // })
        // //兼容留存分析
        // config.legend.push(xix)
        // let newser = JSON.parse(JSON.stringify(ser))
        // newser.name = xix
        // durationList.forEach(dur => {
        //   let duralias = dur.alias
        //   let durval = sult[duralias]
        //   //留存率
        //   if (setting.styleSet.enableRate) {
        //     let aliasarr = dur.alias.split('_')
        //     duralias = 'rate_' + aliasarr[aliasarr.length - 1]
        //     durval = sult[duralias]
        //     durval = durval ? parseFloat(durval.replace('%', '')) : 0

        //     newser.label.formatter = function(v) {
        //       return v.data.toFixed(2) + '%'
        //     }
        //   }
        //   let val = sult ? durval || 0 : 0
        //   newser.data.push(val)
      } else {
        let val = copyRows[0] ? copyRows[0][retentionProps[0].alias] : 0
        ser.data.push(val)
      }

      if (isline == 'line') {
        ser.symbolSize = styleSet.showDataPoint ? 8 : 2
      } else {
        ser.showSymbol = false
      }
      //线的颜色
      if (areaVal) {
        let serLen = config.seriesdata.length
        if (config.relacolors.length <= serLen) {
          config.relacolors = config.relacolors.concat(chart.chartColorV2)
        }
        let serColor = config.relacolors[serLen]
        ser.areaStyle.color = comm.getAreaStyle(_this, basicInfo, themeSet, serColor)
      }
      config.seriesdata.push(ser)
    })

    if (styleSet.enablePencentage) {
      var seriesdatas = _.groupBy(config.seriesdata, 'stack')
      Object.values(seriesdatas).forEach(series => {
        var datas = series.filter(x => !x.isAggregation).map(x => x.data)
        var zipped = _.zip(...datas)
        series.forEach(s => {
          s.data = s.data.filter(t => t != undefined)
          for (var i = 0; i < s.data.length; i++) {
            const filternum = zipped[i].filter(nm => typeof nm == 'number')
            var sum = _.sum(filternum)
            if (sum == 0) {
              s.data[i] = 0
            } else {
              s.data[i] = (s.data[i] * 100) / sum
            }
            s.data[i] = s.data[i].toFixed(2)
          }
        })
      })
    }

    config.xAxisdata = retentionProps.map(x => {
      return x.title
    })
    //辅助线
    _this.$commonJs2.initMarkLine(_this, chart.chartColorV2, config, copyRows, contrastLabels, labelColor)
    option = drows.drowLineV2(_this, config, propSetprops)
  }
  function getRadar() {
    let config = {
      legend: [],
      seriesdata: [],
      indicator: [],
      setting: setting,
      styleSet: styleSet,
      propfmt: null,
      displayType: allprops[0].displayType,
    }
    const prp = propSetprops && propSetprops[0]
    config.propfmt = prp?.format ?? null
    config.legend = hasGroup ? firstGroupList : ['总体']
    measureColumns.map(x => {
      let max = 0
      let numArr = []
      copyRows.map(c => {
        chartprops.map(p => {
          if (p.title.includes(x.title)) {
            numArr.push(Math.abs(c[p.alias]))
          }
        })
      })
      numArr = pushNumArr(styleSet, numArr)
      max = Math.max.apply(null, numArr)
      let cator = {
        name: x.title,
        max: max * 1.2,
        displayType: x.displayType,
      }
      config.indicator.push(cator)
    })

    copyRows.map((x, idx) => {
      getSer(x, idx)
      tails.map(t => {
        let contrastProp = chartprops.filter(c => {
          return c['alias'].includes(t)
        })
        if (contrastProp.length > 0) {
          getSer(x, idx, t)
        }
      })
    })
    function getSer(x, idx, t) {
      let oname = x.otherGroupname ? x.otherGroupname : firstGroupList[idx]
      let ser = {
        name: hasGroup ? (t ? oname + ' - ' + contrastLabels[t] : oname) : '总体',
        lineStyle: {
          // type: lineStyle,
        },
        value: [],
      }
      measureColumns.map(c => {
        let alias = t ? c.alias + t : c.alias
        ser.value.push(x[alias])
      })
      config.seriesdata.push(ser)
    }

    option = drows.drowRadarV2(_this, config)
  }
  function getFunnel() {
    let config = {
      legend: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
    }
    let steps = data.columns.filter(x => {
      return x.usage == 'Measure'
    })
    config.legend = steps.map(function(x) {
      return x.title
    })

    let ser = {
      type: 'funnel',
      data: [],
      top: 26,
      bottom: 20,
    }
    steps.forEach(x => {
      if (x.usage == 'Measure') {
        let list = {
          name: x.title,
          value: data.rows[0][x.alias],
        }
        ser.data.push(list)
      }
    })

    config.seriesdata.push(ser)

    //配置属性
    option = drows.drowFunnelV2(_this, config)

    let group = data.columns.find(x => {
      return x.usage == 'Group'
    })
    option.funnelTips = {
      name: group ? group.title : '总体',
    }
    option.columns = data.columns
    option.total = data.rows.map(x => {
      let rate = {
        ...x,
        title: group ? x.g0 : '全部',
        value: x.overallConversionRate,
      }
      return rate
    })
  }
  function getScatter() {
    let config = {
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      xAxisname: '',
      yAxisname: '',
      max_w: '',
      x_max: '',
      y_max: '',
    }
    config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : measureColumns[0].title
    config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[1].title
    // config.yAxisname = styleSet.yAxisUnit ? config.yAxisname + '(' + styleSet.yAxisUnit + ')' : config.yAxisname
    if (typeof styleSet.xAxisTitle == 'undefined' && typeof styleSet.showXAxisTitle == 'undefined') {
      styleSet.showXAxisTitle = true
    }
    if (typeof styleSet.yAxisTitle == 'undefined' && typeof styleSet.showYAxisTitle == 'undefined') {
      styleSet.showYAxisTitle = true
    }
    let x_numArr = []
    let y_numArr = []
    copyRows.forEach(c => {
      x_numArr.push(c[chartprops[0].alias])
      y_numArr.push(Math.abs(c[chartprops[1].alias]))
    })
    y_numArr = pushNumArr(styleSet, y_numArr)
    config.x_max = Math.max.apply(null, x_numArr)
    config.y_max = Math.max.apply(null, y_numArr)
    const fmtmax = _this.$commonJs.processnumunit(config.y_max, setting, false, styleSet.yAxisformat)
    config.max_w = _this.$commonJs.getWordsWidth(fmtmax)

    copyRows.map(x => {
      if (measureColumns) {
        let ser = {
          name: x.group_key,
          type: 'scatter',
          data: [],
          label: {
            show: styleSet.enableLable,
            offset: [0, -10],
            color: labelColor,
            formatter: function(v) {
              return x.group_key
            },
          },
          columns: measureColumns,
        }
        let scatArr = []
        measureColumns.map(c => {
          scatArr.push(x[c.alias])
        })
        ser.data.push(scatArr)
        config.seriesdata.push(ser)
      }
      tails.forEach(t => {
        let contrastProp = chartprops.filter(c => {
          return c['alias'].includes(t)
        })
        if (contrastProp.length > 0) {
          let ser = {
            type: 'scatter',
            data: [],
            label: {
              show: styleSet.enableLable,
              offset: [0, -10],
              color: labelColor,
              formatter: function() {
                return x.group_key
              },
            },
          }
          ser.name = x.group_key + '-' + contrastLabels[t]
          ser.columns = contrastProp
          ser.label.formatter = function() {
            return x.group_key + '-' + contrastLabels[t]
          }
          let scatArr = []
          contrastProp.forEach(c => {
            scatArr.push(x[c.alias])
          })
          ser.data.push(scatArr)
          config.seriesdata.push(ser)
        }
      })
    })

    //辅助线
    if (config.seriesdata[0]) {
      config.seriesdata[0].markLine = {
        silent: false,
        data: [],
        label: {
          color: labelColor,
        },
      }
    }
    var markLineDataItem = {
      label: {
        show: true,
        position: 'end',
        color: labelColor,
        formatter: function(v) {
          return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
        },
      },
      lineStyle: {
        normal: {
          color: chart.chartColorV2[0],
          width: 1,
          type: 'dashed',
        },
      },
    }
    if (styleSet.subline == 'fixed') {
      if (num_pattern.test(styleSet.xAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.xAxis = styleSet.xAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
      if (num_pattern.test(styleSet.yAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.yAxis = styleSet.yAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
    }
    if (styleSet.subline == 'average') {
      var num = 0
      var name = ''
      var setMarkLine = (alias, aggregation) => {
        var values = copyRows.map(x => x[alias])
        if (aggregation == 'max') {
          num = _this.$lo_.max(values)
          name = '最大值'
        } else if (aggregation == 'min') {
          num = _this.$lo_.min(values)
          name = '最小值'
        } else if (aggregation == 'avg') {
          if (values.length > 0) {
            num = (_this.$lo_.sum(values) / values.length).toFixed(2)
          }
          name = '平均值'
        } else {
          num = median(values)
          name = '中位数'
        }
      }

      setMarkLine(allprops[0].alias, styleSet.xAxisSublineAggregation)
      let item = _this.$lo_.cloneDeep(markLineDataItem)
      item.xAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)

      setMarkLine(allprops[1].alias, styleSet.yAxisSublineAggregation)
      item = _this.$lo_.cloneDeep(markLineDataItem)
      item.yAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)
      config.seriesdata[0].markLine.silent = true
    }

    option = drows.drowScatterV2(_this, config)
  }
  function getGauge() {
    let config = {
      legend: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
    }
    const prp = propSetprops && propSetprops[0]
    const fmt = prp?.format ?? null

    let gaugebg = basicInfo.project.theme == 0 ? 'rgba(241,244,250,1)' : 'rgba(241,244,250,.2)'

    let count = copyRows[0][measureColumns[0].alias]
    //目标值
    let targetname = styleSet.targetname
    let gaugeTarget = styleSet.target
    let tagetvalue = ''
    tagetvalue = gaugeTarget ? ((count * 100) / gaugeTarget).toFixed(2) : count > 0 ? 100 : 0
    let ser = {
      radius: '95%',
      title: {
        show: styleSet.tag,
        offsetCenter: [0, '20%'],
        fontSize: 12,
      },
      type: 'gauge',
      detail: {
        formatter: function(value) {
          const number = fmt ? _this.$commonJs.propformat(count, fmt) : count
          return targetname == 'percent' ? value + '%' : number
        },
      },
      data: [
        {
          value: tagetvalue,
          name: measureColumns[0].title,
        },
      ],
      axisLine: {
        lineStyle: {
          width: 24,
          color: [
            [tagetvalue / 100, chart.chartColorV2[0]],
            [1, gaugebg],
          ],
        },
      },
      axisLabel: {
        color: labelColor,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      pointer: {
        show: true,
        length: '50%',
        width: '4',
      },
    }
    config.seriesdata.push(ser)
    let pieser = {
      name: '小圆形',
      type: 'pie',
      emphasis: {
        scale: false,
      },
      legendHoverLink: false,
      tooltip: {
        show: false,
      },
      center: ['50%', '50%'],
      radius: ['8'],
      z: 20,
      label: {
        normal: {
          show: false,
          position: 'center',
        },
        emphasis: {
          show: false,
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      animation: false,
      data: [
        {
          value: 0,
          name: '1',
          itemStyle: {
            normal: {
              color: 'red',
            },
          },
        },
        {
          value: 100,
          name: '2',
          itemStyle: {
            normal: {
              color: chart.chartColorV2[0],
            },
          },
        },
      ],
    }
    config.seriesdata.push(pieser)

    option = drows.drowGaugeV2(_this, config)
  }
  function getBubble() {
    let config = {
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      xAxisname: '',
      yAxisname: '',
      max_w: '',
      max: '',
      x_max: '',
      y_max: '',
    }
    config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : measureColumns[0].title
    config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[1].title
    // config.yAxisname = styleSet.yAxisUnit ? config.yAxisname + '(' + styleSet.yAxisUnit + ')' : config.yAxisname

    // let max = 0
    let x_numArr = []
    let y_numArr = []
    let maxSymbol = 0
    // if (styleSet.bubbleSize) {
    //   maxSymbol = styleSet.bubbleSize
    // }
    copyRows.forEach(c => {
      if (c[measureColumns[2].alias] > maxSymbol) maxSymbol = c[measureColumns[2].alias]
      x_numArr.push(c[chartprops[0].alias])
      y_numArr.push(Math.abs(c[chartprops[1].alias]))
    })

    y_numArr = pushNumArr(styleSet, y_numArr)
    config.x_max = Math.max.apply(null, x_numArr)
    config.y_max = Math.max.apply(null, y_numArr)
    const fmtmax = _this.$commonJs.processnumunit(config.y_max, setting, false, styleSet.yAxisformat)
    config.max_w = _this.$commonJs.getWordsWidth(fmtmax)

    copyRows.map(x => {
      getSer(x)

      tails.map(t => {
        let contrastProp = chartprops.filter(c => {
          return c['alias'].includes(t)
        })
        if (contrastProp.length > 0) {
          getSer(x, t)
        }
      })
    })
    function getSer(x, t) {
      let ser = {
        name: x.group_key,
        type: 'scatter',
        symbolSize: function(v) {
          let ret = (v[2] * 50) / maxSymbol > 8 ? (v[2] * 50) / maxSymbol : 8
          return store.state.ismobile ? (ret > 25 ? 25 : ret) : ret > 50 ? 50 : ret
        },
        data: [],
        label: {
          show: styleSet.enableLable,
          offset: [0, -10],
          color: labelColor,
          formatter: function(v) {
            return x.group_key
          },
        },
        columns: measureColumns,
      }
      let ser_data = []
      measureColumns.map(c => {
        let alias = t ? c.alias + t : c.alias
        ser_data.push(x[alias])
      })

      ser_data.push(x.group_key)
      ser.data.push(ser_data)

      config.seriesdata.push(ser)
    }
    if (config.seriesdata[0]) {
      config.seriesdata[0].markLine = {
        silent: false,
        data: [],
        label: {
          color: labelColor,
        },
      }
    }
    var markLineDataItem = {
      label: {
        show: true,
        position: 'end',
        color: labelColor,
        formatter: function(v) {
          return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
        },
      },
      lineStyle: {
        normal: {
          color: chart.chartColorV2[0],
          width: 1,
          type: 'dashed',
        },
      },
    }
    if (styleSet.subline == 'fixed') {
      if (num_pattern.test(styleSet.xAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.xAxis = styleSet.xAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
      if (num_pattern.test(styleSet.yAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.yAxis = styleSet.yAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
    }
    if (styleSet.subline == 'average') {
      var num = 0
      var name = ''
      var setMarkLine = (alias, aggregation) => {
        var values = copyRows.map(x => x[alias])
        if (aggregation == 'max') {
          num = _this.$lo_.max(values)
          name = '最大值'
        } else if (aggregation == 'min') {
          num = _this.$lo_.min(values)
          name = '最小值'
        } else if (aggregation == 'avg') {
          if (values.length > 0) {
            num = (_this.$lo_.sum(values) / values.length).toFixed(2)
          }
          name = '平均值'
        } else {
          num = median(values)
          name = '中位数'
        }
      }
      setMarkLine(allprops[0].alias, styleSet.xAxisSublineAggregation)
      let item = _this.$lo_.cloneDeep(markLineDataItem)
      item.xAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)

      setMarkLine(allprops[1].alias, styleSet.yAxisSublineAggregation)
      item = _this.$lo_.cloneDeep(markLineDataItem)
      item.yAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)
      config.seriesdata[0].markLine.silent = true
    }

    option = drows.drowScatterV2(_this, config)
  }

  function getRelation() {
    let config = {
      datas: [],
      links: [],
      relacolors: [],
      setting: setting,
      styleSet: styleSet,
    }
    //拿到所有的分组名
    const g0s = copyRows.map(x => {
      return x['g0']
    })

    const g1s = copyRows.map(x => {
      return x['g1']
    })

    const allNames = [...new Set([...g0s, ...g1s])]
    config.relacolors = comm.getArryColors(chart.chartColorV2, allNames.length)

    //新的数组
    let incidNewRows = []
    //根据分组名得到一个字典
    let dictionDic = {}
    const incidNumberArr = styleSet.incidNumber.split('_')
    allNames.forEach(name => {
      const list = copyRows.filter(x => {
        return x['g0'] == name
      })
      incidNewRows = incidNewRows.concat(list.splice(0, incidNumberArr[1]))
    })
    allNames.forEach(name => {
      const list = incidNewRows.filter(x => {
        return x['g0'] == name
      })
      dictionDic[name] = list
    })
    //最大值
    let maxValue = 0
    let newlist = []
    let addList = []
    incidNewRows.forEach(x => {
      maxValue = x['c0'] + x['c1'] > maxValue ? x['c0'] + x['c1'] : maxValue
      let groups = newlist.map(n => {
        return n.source + '-' + n.target
      })
      let gs = x['g0'] + '-' + x['g1']
      if (!groups.includes(gs)) {
        let list = {
          source: x['g0'],
          target: x['g1'],
          value: x['c0'],
        }
        //排除有重复的
        if (addList.includes(list.source + '-' + list.target) || addList.includes(list.target + '-' + list.source)) {
        } else {
          addList.push(list.source + '-' + list.target)
          newlist.push(list)
        }
      }
    })
    config.links = newlist

    config.datas = allNames.map((x, idx) => {
      const currow = incidNewRows.find(r => {
        return r['g0'] == x
      })
      let size = currow ? ((currow['c0'] + currow['c1']) / maxValue) * 80 : 10
      size = size > 10 ? size : 10
      return {
        name: x,
        id: x,
        itemStyle: {
          color: config.relacolors[idx].color,
          opacity: config.relacolors[idx].opacity,
        },
        label: {
          color: config.relacolors[idx].color,
        },
        symbolSize: size,
        lists: dictionDic[x],
      }
    })

    option = drows.drowRelationV2(_this, config)
  }
  function getMap() {
    option.mapConfig = getmapConfig()
  }
  function getColor(value, nums, colors) {
    let color = ''
    nums.forEach((x, i) => {
      if (i < 9 && value > x[0] && value <= x[1]) {
        color = colors[i]
      }
      if (i == 9 && value > x[0]) {
        color = colors[i]
      }
    })
    return color
  }
  function getmapConfig() {
    const colors = ['#EFE387', '#DEDB99', '#B9D896', '#B6D5F4', '#C2B5FF', '#FFA9FF', '#F2ACAC', '#C894E3 ', '#8282FF', '#0070FF']
    let measure = copyColumns.filter(x => {
      return x.usage == 'Measure'
    })[0]
    const shopidColumn = copyColumns.find(x => {
      return x.fullAlias == 'shop__shop_id_dim'
    })
    const shopnameColumn = copyColumns.find(x => {
      return x.fullAlias == 'shop__shop_name_dim'
    })
    let max = ''
    copyRows.forEach(x => {
      max = x[measure.alias] > max ? x[measure.alias] : max
    })
    max = styleSet.enableMax == 'auto' ? max : styleSet.enableFixed ? styleSet.enableFixed : max
    let nums = Array.from(new Array(10), (item, k) => [parseFloat(((max * k) / 10).toFixed(2)), parseFloat(((max * (k + 1)) / 10).toFixed(2))])

    let shopData = copyRows.map(x => {
      let mapAreaId = hotmapdata.mappings[x[shopidColumn.alias]]
      let shop = {
        bizId: x[shopidColumn.alias],
        name: x[shopnameColumn.alias],
        mapAreaId: mapAreaId ? mapAreaId[0] : '00',
        Fill: getColor(x[measure.alias], nums, colors),
        toastHtml: `<dl>
        <dd>${x[shopnameColumn.alias]}：${x[measure.alias]} ${styleSet.enableUnit ? '(' + styleSet.enableUnit + ')' : ''}</dd>
          </dl>`,
      }
      return shop
    })
    let markData = copyRows.map(x => {
      let mapAreaId = hotmapdata.mappings[x[shopidColumn.alias]]
      let mark = {
        bizId: x[shopidColumn.alias],
        name: styleSet.enableLable ? x[shopnameColumn.alias] : '',
        mapAreaId: mapAreaId ? mapAreaId[0] : '00',
      }
      return mark
    })
    let floorMap = hotmapdata.floorMap.replace('↵', '')
    let labelData = nums.map((x, i) => {
      let label = {
        labelName: x[0],
        bgColor: colors[i],
      }
      return label
    })
    let mapConfig = {
      maxScale: 20,
      zoomScale: 0.2,
      txtGap: 10,
      mapGap: 150,
      defaultFill: '#eeeeee',
      gTxtColor: '#666666',
      labelData: labelData,
      shopData: shopData,
      markData: markData,
      svg: floorMap,
      max: max,
      floor: hotmapdata.floorName,
    }
    return mapConfig
  }
  function gettable_more() {
    option.activeTabName = 'tab0'
    option.data = gettabledata()
  }
  function gettable_hotmap() {
    option = tbdata
    option.opts = opts
    option.field = {}
    option.thead.forEach(x => {
      option.field[x.label] = x.prop
    })
  }
  function getTable() {
    option = tbdata
    option.opts = opts
    option.field = {}
    option.thead.forEach(x => {
      option.field[x.label] = x.prop
    })
  }

  function getSankey() {
    let config = {
      legend: [],
      setting: setting,
      styleSet: styleSet,
      direction: '',
      dictionary: {},
      datas: [],
      links: [],
    }

    let pathcomp = query.parameter.components.find(x => {
      return x.type == 'Path'
    })
    let direction = pathcomp.initial.direction
    config.direction = direction

    let filterdata = copyRows.filter(el => {
      return el.path.indexOf('-->') > 0
    })
    let newSet = new Set()
    let newList = []
    filterdata.forEach(el => {
      newList.push({ name: getTail(el, 0, 'l'), label: { position: 'right' } })
      newList.push({ name: getTail(el, 1, 'r'), label: { position: 'left' } })
    })
    newList.forEach(el => {
      let jsonel = el.name
      let setLen = [...newSet].length
      newSet.add(jsonel)
      if ([...newSet].length > setLen) {
        config.datas.push(el)
      }
    })

    config.links = copyRows
      .filter(el => {
        let isPath = el.path.indexOf('-->') > 0
        if (config.direction == 'both') {
          let hide = el.path_raw.split('-->')[0] == el.path_raw.split('-->')[1]
          return isPath && !hide
        } else {
          return isPath
        }
      })
      .map(el => {
        return {
          source: getTail(el, 0, 'l'),
          target: getTail(el, 1, 'r'),
          value: el.count,
        }
      })

    //去掉多余 datas
    let linksSet = new Set()
    config.links.forEach(x => {
      linksSet.add(x.source)
      linksSet.add(x.target)
    })
    const lisksNames = [...linksSet]
    config.datas = config.datas.filter(x => {
      return lisksNames.includes(x.name)
    })

    function getTail(el, i, position) {
      let name = el.path.split('-->')[i]
      const name_raw = el.path_raw.split('<:>')[i]
      const leftTail = ' '
      const rightTail = '  '
      const middleTail = ''
      if (config.direction == 'both' && name_raw.indexOf('[*]') > 0) {
        //双向时标记的为中间节点
        name = name + middleTail
      } else {
        name = name + (position == 'l' ? leftTail : rightTail)
      }
      return name
    }

    config.links.forEach(x => {
      if (!config.dictionary[x.source]) config.dictionary[x.source] = { allnums: 0, list: [] }
      config.dictionary[x.source].allnums += x.value
      config.dictionary[x.source].list.push(x)
    })
    //配置属性
    option = drows.drowSankeyV2(_this, config)
  }

  function getWordCloud() {
    let config = {
      datas: [],
      propfmt: null,
      setting: setting,
      styleSet: styleSet,
    }
    const prp = propSetprops && propSetprops[0]
    config.propfmt = prp?.format ?? null
    let cloudColors = comm.getArryColors(chart.chartColorV2, copyRows.length)
    copyRows = copyRows.sort((a, b) => {
      return b[allprops[0].alias] - a[allprops[0].alias]
    })
    config.datas = copyRows.map((x, i) => {
      return {
        name: x[groups[0].alias],
        value: x[allprops[0].alias],
        textStyle: {
          color: cloudColors[i].color,
        },
      }
    })

    let chartoption = drows.drowWordCloud(_this, config)
    option = {
      ...option,
      ...chartoption,
    }
  }
  function getTreemap() {
    let config = {
      datas: [],
      setting: setting,
      styleSet: styleSet,
      colors: chart.chartColorV2,
      propfmt: null,
      displayType: allprops[0].displayType,
    }
    const prp = propSetprops && propSetprops[0]
    config.propfmt = prp?.format ?? null

    let allNums = 0
    copyRows.forEach(x => {
      allNums += x[allprops[0].alias]
    })
    const children = copyRows.map(x => {
      return {
        name: x[groups[0].alias],
        value: x[allprops[0].alias],
        percent: ((x[allprops[0].alias] * 100) / allNums).toFixed(2),
      }
    })

    config.datas = [
      {
        name: groups[0].title,
        children: children,
      },
    ]

    let chartoption = drows.drowTreemap(_this, config)
    option = {
      ...option,
      ...chartoption,
    }
  }

  function getMap3d() {
    let config = {
      datas: '',
      max: 0,
    }
    // config.datas = d.data
    // config.datas = data
    option = {
      ...option,
      ...config,
    }
  }

  //获取表格数据
  function gettabledata() {
    let tabledata = []
    let first = {
      tabname: '总览',
      tableHead: data.columns,
      opt: data.rows,
    }
    tabledata.push(first)

    data.funnels.forEach(x => {
      let list = {
        tabname: x.step,
        tableHead: x.columns,
        opt: x.rows,
      }
      tabledata.push(list)
    })
    return tabledata
  }

  //获取不同事件
  function getMeasureCode(e) {
    let code = ''
    measureColumns.forEach(p => {
      if (e.alias.includes(p.alias)) {
        code = p.alias
      }
    })
    return code
  }
  function getMeasureName(e) {
    let name = ''
    measureColumns.forEach(p => {
      if (e.alias.includes(p.alias)) {
        name = p.title
      }
    })
    return name
  }

  function getMeasureTail(e) {
    let tail = ''
    if (e.alias.includes('_contrast')) {
      tail = ' - 对比'
    }
    if (e.alias.includes('_last')) {
      tail = ' - 同比'
    }
    if (e.alias.includes('_circle')) {
      tail = ' - 环比'
    }
    return tail
  }

  function getLineStyle(e) {
    if (e.alias.includes('Rate')) {
      return 'solid'
    }
    if (e.alias.includes('_contrast')) {
      return 'dotted'
    }
    if (e.alias.includes('_last')) {
      return 'dotted'
    }
    if (e.alias.includes('_circle')) {
      return 'dashad'
    }
  }

  //Y轴范围
  function pushNumArr(styleSet, numArr) {
    const areaYAxis = styleSet.areaYAxis
    if (styleSet.showYAxis && areaYAxis) {
      if (areaYAxis[0]) numArr.push(Math.abs(areaYAxis[0]))
      if (areaYAxis[1]) numArr.push(Math.abs(areaYAxis[1]))
    }
    return numArr
  }
  function contrastMax(styleSet, maxNum) {
    const areaYAxis = styleSet.areaYAxis
    if (styleSet.showYAxis && (areaYAxis[0] || areaYAxis[1])) {
      const maxarea = Math.abs(areaYAxis[1]) > Math.abs(areaYAxis[0]) ? Math.abs(areaYAxis[1]) : Math.abs(areaYAxis[0])
      maxNum = maxNum > maxarea ? maxNum : maxarea
    }
    return maxNum
  }

  function getDateWeek(datestr) {
    const weeks = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
    let index = _this.moment(datestr).weekday()
    return weeks[index]
  }

  function getCityWeatherData() {
    let arr = []
    let weatherData = store.state.weathers.data
    if (weatherData.length == 0) {
      return arr
    }
    let firstGroup = data.columns.find(x => x.usage == 'Group')
    let dates = data.rows.map(x => x[firstGroup.alias])
    dates.forEach(date => {
      let matched = weatherData.find(x => x[0] == date)
      if (matched) {
        arr.push([matched[0], 0, matched[1], matched[2], matched[3], matched[4]])
      }
    })
    return arr
  }

  let prePointx = 0
  function renderWeather(param, api) {
    let point = api.coord([api.value(0), 0])
    let path = `assets/images/weather`
    let color = labelColor
    let badWeathers = ['多云', '晴', '阴', '雾']
    if (badWeathers.includes(api.value(2))) {
      path = `assets/images/weather/${basicInfo.project.theme == 1 ? 'dark' : 'light'}`
    } else {
      color = 'red'
    }

    prePointx = prePointx > point[0] ? 0 : prePointx
    if (prePointx > 0) {
      if (point[0] - prePointx < 70) {
        return null
      }
    }

    prePointx = point[0]
    return {
      type: 'group',
      children: [
        {
          type: 'image',
          style: {
            image: require(`@/${path}/${api.value(2)}.svg`),
            x: -18 / 2,
            y: -18 / 2,
            width: 18,
            height: 18,
          },
          position: [point[0], 47],
        },
        {
          type: 'text',
          style: {
            text: api.value(3) + '-' + api.value(4),
            textFont: api.font({
              fontSize: 12,
            }),
            fill: color,
            textAlign: 'center',
            textVerticalAlign: 'bottom',
          },
          position: [point[0], 35],
        },
      ],
    }
  }

  if (!chart.otherset || !chart.otherset.slot || chart.otherset.slot == 'day') {
    if (styleSet.enableWeather) {
      let weatherData = getCityWeatherData()
      if (weatherData.length > 0) {
        let weatherSeries = {
          type: 'custom',
          renderItem: renderWeather,
          data: weatherData,
          tooltip: {
            trigger: 'item',
            formatter: function(param) {
              return (
                '&nbsp' +
                param.value[0] +
                '&nbsp' +
                param.value[2] +
                '&nbsp<br>&nbsp' +
                param.value[3] +
                ' - ' +
                param.value[4] +
                '&nbsp<br>&nbsp' +
                param.value[5] +
                ' &nbsp'
              )
            },
          },
        }
        option.series.push(weatherSeries)
        option.grid.top = option.grid.top + 40
      }
    }
    if (styleSet.enableDateExtend && styleSet.enableDateExtend != 'none') {
      let xAxis = option.xAxis instanceof Array ? option.xAxis[0] : option.xAxis
      let formatter = xAxis.axisLabel.formatter || (v => v)
      let color = xAxis.axisLabel.color
      xAxis.axisLabel.lineHeight = 18
      if (['statutory', 'custom'].includes(styleSet.enableDateExtend)) {
        let dateFestivalDescs = styleSet.enableDateExtend == 'statutory' ? store.state.festivals.statutory : store.state.festivals.custom
        if (dateFestivalDescs && Object.keys(dateFestivalDescs).length > 0) {
          xAxis.axisLabel.formatter = v => formatter(v) + '\n' + (dateFestivalDescs[v] || '')
          xAxis.axisLabel.color = v => (dateFestivalDescs[v] ? 'red' : color)
        }
      } else if (styleSet.enableDateExtend == 'week') {
        xAxis.axisLabel.formatter = v => formatter(v) + '\n' + (getDateWeek(v) || '')
        xAxis.axisLabel.color = v => ([6, 0].includes(_this.moment(v).weekday()) ? 'red' : color)
      }
    }
  }

  function numUnit(num) {
    const propItem = propSetprops && propSetprops[0]
    const fmt = propItem?.format ?? null
    let denominator = 1
    if (styleSet.numberunit == 'tenthousand') {
      option.numberunit = '万'
      denominator = 10000
    } else if (styleSet.numberunit == 'million') {
      option.numberunit = '百万'
      denominator = 1000000
    } else if (styleSet.numberunit == 'billion') {
      option.numberunit = '亿'
      denominator = 100000000
    } else if (styleSet.numberunit == 'thousand') {
      option.numberunit = 'K'
      denominator = 1000
    } else if (styleSet.numberunit == 'trillion') {
      option.numberunit = 'M'
      denominator = 1000000
    } else {
      option.numberunit = ''
    }
    const fmtendnum = parseFloat(num / denominator)
    const endnum = parseFloat((num / denominator).toFixed(2))
    return fmt ? _this.$commonJs.propformat(fmtendnum, fmt) : endnum
  }

  return option
}
