<template>
  <div v-if="carddata" class="drawcard">
    <gridBar :cardData="carddata" :isshow="isshow" @searchchange="searchchange" />
    <div
      v-if="carddata.searchCode === 'combination'"
      class="charts-box"
      :style="{ height: carddata.timeRange ? 'calc(100% - 60px)' : 'calc(100% - 40px)' }"
      :key="carddata.cardkey"
    >
      <template>
        <div class="carddashboard" style="height: 100%">
          <carddashboard
            :ref="'carddashboard'"
            :chartDataItem="carddata"
            :griddata="carddata.reqObj.cardGrid"
            :cardlist="carddata.reqObj.cardlist"
          ></carddashboard>
        </div>
      </template>
    </div>
    <div v-if="carddata.searchCode !== 'combination'" class="charts-box" :style="{ height: carddata.timeRange ? 'calc(100% - 60px)' : 'calc(100% - 40px)' }">
      <div v-if="carddata.option && carddata.option.noData" class="nodata dis_flex_center">
        <div>
          <img src="~@/assets/images/nodata-bg.png" alt />
          <span>暂无数据</span>
        </div>
      </div>

      <template v-else>
        <template v-if="carddata.version == '2.0'">
          <vue-bi-echarts
            v-if="carddata.cardinfos"
            :ref="'chart' + carddata.id"
            :key="'bicardkey' + carddata.id"
            :cardinfos="carddata.cardinfos"
            :rowheight="carddata.timeRange ? cardheight - 80 : cardheight - 60"
            :dontclickable="true"
          ></vue-bi-echarts>
        </template>
        <template v-else>
          <div
            class="chart-table funnel-table"
            v-if="
              carddata.option &&
                ((carddata.reqObj.mode == 'funnel' && carddata.reqObj.showType && carddata.reqObj.showType.code == 'table') ||
                  carddata.reqObj.mode == 'table_more')
            "
            :ref="'charttable'"
            :key="'funnel'"
          >
            <funneltable
              :ref="'eltable'"
              :chartdata.sync="carddata"
              :rowheight="carddata.timeRange ? cardheight - 80 : cardheight - 60"
              :dontclickable="true"
            />
          </div>
          <div
            class="chart-table common-table"
            v-else-if="carddata.option && ['table', 'table_hotmap', 'table_path'].includes(carddata.reqObj.mode)"
            :ref="'charttable'"
          >
            <my-table
              :ref="'eltable'"
              :id="'eltable'"
              :col="carddata.option.thead"
              :data="carddata.option.tbody"
              :opts="carddata.option.opts"
              :chartoption="carddata"
              :resizedidx="carddata.resizedidx"
              :rowheight="carddata.timeRange ? cardheight - 80 : cardheight - 60"
              :dontclickable="true"
            ></my-table>
          </div>
          <div
            class="cardbox dis_flex_center"
            v-else-if="carddata.option && carddata.reqObj.mode == 'card' && carddata.curcardlabel"
            :ref="'chartcard'"
            :class="{ nolimit: carddata.option.numberFontSize == 'auto' }"
          >
            <div v-if="carddata.reqObj.parameter.groups && carddata.reqObj.parameter.groups.length > 0" class="card-select">
              <el-select v-model="carddata.curcardlabel" size="mini" @change="cardchange">
                <el-option v-for="item in carddata.option.arr" :key="item.label" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </div>
            <div class="cardinner" :class="'carditem4'">
              <div class="cardmeasure" v-if="carddata.option.showMeasure" :style="{ 'font-size': carddata.option.measureNameFontSize }">
                {{ carddata.option.measureName }}
              </div>
              <div class="cardnum" :style="cardMainColor(carddata)">
                <span>{{ carddata.curcard.value }}</span>
                <b
                  :style="{
                    'font-size': parseInt(carddata.option.numberFontSize) > 36 ? parseInt(carddata.option.numberFontSize) / 3 + 'px' : '12px',
                  }"
                  >{{ carddata.option.numberunit }}{{ carddata.option.unitName }}</b
                >
              </div>
              <div class="card-contrast dis_flex_center">
                <div
                  class="card-contrast-item"
                  v-show="carddata.curcard.cardConrastdata.length > 0"
                  v-for="(carditem, cardidx) in carddata.curcard.cardConrastdata"
                  :key="cardidx"
                >
                  <label v-text="carditem.title"></label>
                  <span
                    :class="{
                      'arrow-down': carditem.value.includes('-'),
                      'arrow-up': !carditem.value.includes('-'),
                      red_green: basicInfo.project.contrastTrendColor && basicInfo.project.contrastTrendColor == 1,
                    }"
                  >
                    <b v-text="carditem.value"></b>
                    <!-- <i :class="carditem.value.includes('-') ? 'el-icon-bottom' : 'el-icon-top'"></i> -->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mapbox" v-else-if="carddata.option && carddata.reqObj.mode == 'map'" :ref="'chartmap'" style="height: 100%">
            <BIMap :map-config="carddata.option.mapConfig" :idx="0" :key="carddata.mapkey"></BIMap>
          </div>
          <echartItem v-else-if="carddata.option" :resdata="carddata" :parent="parentset"></echartItem>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import gridBar from './gridBar'
import echartItem from '@/components/echartItem'
import MyTable from '@/components/tables/MyTable'
import funneltable from '@/views/dashboard/funneltable'
import carddashboard from '@/views/dashboard/carddashboard/carddashboard'
import dashbdjs from './dashboardjs'
import { allColor } from '@/datajs/static.js'

export default {
  props: ['chartdata', 'cardheight', 'isshow'],
  data() {
    return {
      carddata: null,
      parentset: {}, //父组件操作
    }
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  components: { gridBar, echartItem, MyTable, funneltable, carddashboard },
  watch: {
    'carddata.option': function() {
      if (this.carddata.option) {
        this.emitSucess()
      }
    },
  },
  created() {
    this.carddata = this.chartdata
  },
  methods: {
    gettimeRange(time) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = 0
      newitem.item = this.carddata
      if (this.carddata.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '')
      } else {
        dashbdjs.gettimeRange(this, newitem, '')
      }
    },
    cardMainColor(item) {
      let col = []
      let colors = ''
      if (item.rdMainColor == 0) {
        col = allColor['color' + this.basicInfo.project.mainColor]
      } else {
        col = allColor['color' + (item.rdMainColor - 1)]
      }

      colors = col[item.rdChartColor]
      let numberFontSize = item.option.numberFontSize
      let size = numberFontSize == 'auto' ? '' : numberFontSize
      let fontsize = size ? 'font-size:' + size : ''
      let sty = fontsize ? 'color:' + colors[0] + ';' + fontsize : 'color:' + colors[0]
      return sty
    },
    cardchange(item) {
      this.$set(this.carddata, 'curcardlabel', item)
      const curcard = this.carddata.option.arr.find(x => x.label === item)
      this.$set(this.carddata, 'curcard', curcard)
    },
    searchchange(type) {
      this.carddata.option = null
      dashbdjs.searchchange(this, this.carddata, this.carddata.reqObj, this.carddata.reqObj.contrastType, type)
    },
    emitSucess() {
      const _this = this
      setTimeout(() => {
        if (!_this.isshow) {
          if (_this.chartdata && _this.carddata.option && _this.chartdata.option.noData) {
            _this.$emit('drawsuccess')
          } else {
            _this.$emit('drawsuccess')
          }
        }
      }, 500)
    },
  },
  mounted() {
    this.emitSucess()
  },
}
</script>

<style lang="scss" scoped>
.drawcard {
  width: 100%;
  height: 100%;
  .charts-box {
    width: 100%;
    height: 100%;
    .chart-table {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin-top: 10px;
      border-top: 1px solid #ebeef5;
      border-radius: 6px;
      &.funnel-table {
        border-top: none !important;
      }
    }
    .cardbox {
      width: 100%;
      height: 90%;
      position: relative;
      .card-select {
        position: absolute;
        left: 0;
        top: 0;
      }
      .cardmeasure {
        font-size: 16px;
        text-align: center;
        @include theme_color2($theme-light);
      }
      .cardnum {
        text-align: center;
        font-family: newfont1;
        font-size: 24px;
        span {
          font-size: inherit;
        }
        b {
          font-size: 12px;
          margin-left: 7px;
        }
      }
      .card-contrast {
        .card-contrast-item {
          font-size: 14px;
          &:nth-of-type(1) {
            margin-right: 10px;
          }
          &:nth-of-type(2) {
            margin-left: 10px;
          }
          label {
            color: #adb5bd;
            margin-right: 5px;
          }
          span {
            b {
              font-weight: normal;
              font-family: newfont1;
            }
          }
        }
      }
      &.nolimit {
        .cardmeasure {
          font-size: 24px;
        }
        .cardnum {
          font-size: 72px;
          b {
            font-size: 24px !important;
          }
        }
      }
      &.nolimit .carditem4,
      &.nolimit .carditem5 {
        .cardmeasure {
          font-size: 18px;
        }
        .cardnum {
          font-size: 48px;
          b {
            font-size: 16px !important;
          }
        }
      }
      &.nolimit .carditem3 {
        .cardmeasure {
          font-size: 16px;
        }
        .cardnum {
          font-size: 36px;
          b {
            font-size: 12px !important;
          }
        }
        .card-contrast {
          .card-contrast-item {
            font-size: 12px;
          }
        }
      }
      &.nolimit .carditem2 {
        .cardmeasure {
          font-size: 14px;
        }
        .cardnum {
          font-size: 24px;
          b {
            font-size: 12px !important;
          }
        }
        .card-contrast {
          .card-contrast-item {
            font-size: 12px;
          }
        }
      }
      &.nolimit .carditem1 {
        .cardmeasure {
          font-size: 12px;
        }
        .cardnum {
          font-size: 24px;
          b {
            font-size: 12px !important;
          }
        }
        .card-contrast {
          .card-contrast-item {
            font-size: 12px;
          }
        }
      }
    }
    .carddashboard {
      position: relative;
    }

    .nodata {
      width: 100%;
      height: 100%;
      text-align: center;
      img {
        display: block;
        width: 80px;
        margin: 0 auto;
      }
      span {
        display: inline-block;
        padding-left: 15px;
        font-size: 14px;
        margin-top: 5px;
        color: #adb5bd;
      }
    }
  }
}
</style>
