<template>
  <styleTemp
    v-if="tempitem.chartdata"
    :showAppear="showAppear"
    :styleSet.sync="tempitem.chartdata.reqObj.settings.styleSet"
    :type="tempitem.chartdata.reqObj.settings.type"
    :columns="tempitem.chartdata.rawdata && tempitem.chartdata.rawdata.columns"
    :tempitem="tempitem"
    @setChange="setStyleChange"
  />
</template>

<script>
import styleTemp from './styleTemp'
import chartStyleSetList from './chartStyleData.js'

export default {
  props: ['tempitem'],
  data() {
    return {
      showAppear: [],
      settings: {},
      analysisType: '',
    }
  },
  components: { styleTemp },
  created() {
    this.analysisType = this.tempitem.chartdata.searchCode
    this.init()
    this.initChartSet()
    this.initStyleSet()
  },
  methods: {
    init() {},
    initChartSet() {
      // 初始化获取当前类型的展示数据 和设置相关数据 集合成页面展示数据
      let cardtype = this.tempitem.chartdata.reqObj.mode
      this.showAppear = _.cloneDeep(chartStyleSetList[cardtype]) || []
      // 根据指标和分析维度 来设置选项是否禁用
      const rawdata = this.tempitem.chartdata.rawdata
      const parameter = this.tempitem.chartdata.reqObj?.parameter ?? {}
      const columns = rawdata?.columns ?? []
      const paging = parameter?.paging ?? ''
      const contrastType = parameter?.contrastType ?? 'Current'
      this.initSetTypes(columns, paging, contrastType, this.showAppear, this.analysisType, cardtype)
    },
    //加工表格设置
    initSetTypes(columns, paging, contrastType, showAppear, analysisType, chartType) {
      const _that = this
      let eventLen = columns.filter(x => x.usage == 'Measure').length
      let groupLen = columns.filter(x => x.usage == 'Group').length
      let firstGroup = columns.find(x => x.usage == 'Group')
      let lens = {
        eventLen: eventLen,
        groupLen: groupLen,
      }
      showAppear.forEach(el => {
        el.enabled = true
        if (el.rule) {
          el.enabled = _that.isSetTypeEnabled(paging, el.rule, lens, contrastType, firstGroup, analysisType, chartType)
        }
        if (el.enabled) {
          el.list.forEach(x => {
            x.enabled = !x.rule || _that.isSetTypeEnabled(paging, x.rule, lens, contrastType, firstGroup, analysisType, chartType)
            if (x.radios) {
              x.radios.forEach(r => {
                r.disabled = r.rule ? !_that.isSetTypeEnabled(paging, r.rule, lens, contrastType, firstGroup, analysisType, chartType) : false
              })
            }
            return x
          })
          if (el.list.every(x => !x.enabled)) el.enabled = false
        } else {
          el.list.forEach(x => (x.enabled = false))
        }
      })
    },
    isSetTypeEnabled(paging, rule, lens, contrastType, firstGroup, analysisType, chartType) {
      let enabled = false
      if (rule.behaviorType) {
        enabled = rule.behaviorType(analysisType)
        if (!enabled) return false
      }
      if (rule.propCheck) {
        enabled = rule.propCheck(lens.eventLen)
        if (!enabled) return false
      }
      if (rule.groupCheck) {
        enabled = rule.groupCheck(lens.groupLen)
        if (!enabled) return false
      }
      if (rule.contrastCheck) {
        enabled = rule.contrastCheck(contrastType)
        if (!enabled) return false
      }
      if (rule.fristGroupCheck) {
        enabled = rule.fristGroupCheck(firstGroup)
        if (!enabled) return false
      }
      if (rule.pagingIsNull) {
        enabled = rule.pagingIsNull(paging)
        if (!enabled) return false
      }
      if (rule.chartCheck) {
        enabled = rule.chartCheck(chartType)
        if (!enabled) return false
      }
      return enabled
    },

    initStyleSet() {
      const rawdata = this.tempitem.chartdata.rawdata
      const columns = rawdata?.columns ?? []
      this.settings = this.tempitem.chartdata.reqObj.settings
      const settings = JSON.parse(JSON.stringify(this.settings))
      let { styleSet = {}, type } = settings
      let measureColumns = columns.filter(x => x.usage == 'Measure').map(x => ({ value: x.alias, label: x.title }))
      const groupColumns = columns.filter(x => x.usage == 'Group').map(x => ({ value: x.alias, label: x.title }))
      //留存去掉第一个指标
      if (this.analysisType === 'Retention') {
        measureColumns = measureColumns.filter(x => x.value !== 'total')
      }
      if (styleSet.subline == 'measureField') {
        let sublineField = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'sublineField')
        sublineField.select = measureColumns
        sublineField.defaultValue = measureColumns[0] && measureColumns[0].value
        if (!styleSet['sublineField']) styleSet['sublineField'] = sublineField.defaultValue
      }
      //柱图+线图
      if (type == 'bar_line') {
        let lineprops = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'lineprops')
        lineprops.selectMultiple = measureColumns
        lineprops.defaultValue = measureColumns[1] && [measureColumns[1].value]
        if (!styleSet['lineprops']) styleSet['lineprops'] = lineprops.defaultValue
      }

      //双轴图
      if (type == 'biaxial_graph') {
        let lineprops = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'lineprops')
        lineprops.selectMultiple = measureColumns
        if (styleSet['lineprops'].length == 0 && styleSet['nextLineprops'].length == 0) {
          lineprops.defaultValue = measureColumns[0] && [measureColumns[0].value]
          styleSet['lineprops'] = lineprops.defaultValue
        } else if (styleSet['lineprops'].length == 0 && styleSet['nextLineprops'].length !== measureColumns.length) {
          const Values = measureColumns.map(x => x.value).filter(x => !styleSet['nextLineprops'].includes(x))
          styleSet['lineprops'] = Values
        } else if (styleSet['nextLineprops'].length === measureColumns.length) {
          lineprops.defaultValue = []
        }
        //次Y轴
        let nextLineprops = this.showAppear.flatMap(x => [...x.list]).find(x => x.code == 'nextLineprops')
        nextLineprops.selectMultiple = measureColumns
        const nextValues = measureColumns.map(x => x.value).filter(x => !styleSet['lineprops'].includes(x))
        nextLineprops.defaultValue = nextValues
        styleSet['nextLineprops'] = nextValues
      }

      let codes = []
      let props = Object.keys(styleSet)
      this.showAppear.forEach(item => {
        if (item.enabled && (!item.dependency || item.dependency(styleSet, type))) {
          item.list.forEach(x => {
            if (!x.dependency || x.dependency(styleSet, type)) {
              if (!props.includes(x.code)) styleSet[x.code] = x.defaultValue

              if (x.select || x.radios) {
                if (!(x.select || x.radios).map(y => y.value).some(y => y == styleSet[x.code])) {
                  styleSet[x.code] = x.defaultValue
                }
              }
              if (x.selectMultiple) {
                if (!x.selectMultiple.map(y => y.value).some(y => styleSet[x.code].includes(y))) {
                  styleSet[x.code] = x.defaultValue
                }
              }
              //默认标题
              if (['line', 'bar', 'bar_line'].includes(settings.type)) {
                if (x.code == 'yAxisTitle' && !x.label) {
                  styleSet[x.code] = styleSet[x.code] ? styleSet[x.code] : measureColumns[0].label
                }
                if (x.code == 'xAxisTitle' && !x.label) {
                  styleSet[x.code] =
                    (groupColumns.length > 0 && groupColumns[0].label) || styleSet[x.code] ? (styleSet[x.code] ? styleSet[x.code] : groupColumns[0].label) : ''
                }
              }
              if (['biaxial_graph'].includes(this.settings.type)) {
              }
              if (['scatter', 'bubble'].includes(settings.type)) {
                if (x.code == 'yAxisTitle' && !x.label) {
                  styleSet[x.code] = styleSet[x.code] ? styleSet[x.code] : measureColumns[1].label
                }
                if (x.code == 'xAxisTitle' && !x.label) {
                  styleSet[x.code] = styleSet[x.code] ? styleSet[x.code] : measureColumns[0].label
                }
              }
              codes.push(x.code)
            } else {
              delete styleSet[x.code]
            }
          })
        } else {
          item.list.forEach(x => delete styleSet[x.code])
        }
      })
      //删除无效的字段
      props.forEach(x => {
        if (!codes.includes(x)) delete styleSet[x]
      })

      this.showAppear = _.cloneDeep(this.showAppear)
      this.settings = _.cloneDeep(settings)
    },

    setStyleChange(style) {
      if (style && style.code) {
        this.initStyleSet()
        if (style.code == 'allDimValues') {
          this.settings.preventReload = true
        }
      }
      this.$emit('set-change', this.settings)
    },
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.appearstyle {
  margin-bottom: 10px;
  h4 {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 5px;
    border-bottom: 1px solid #dcdfe6;
  }
  ul {
    li {
      display: flex;
      line-height: 32px;
      > label {
        width: 78px;
        text-align: left;
        margin-right: 5px;
        font-size: 14px;
      }
      .label-r {
        flex: 1;
        &.label-r-input {
          display: flex;
          span {
            margin-right: 5px;
            margin-left: 4px;
          }
        }
        .el-slider {
          padding-right: 10px;
        }
        .el-radio-group {
          .el-radio {
            line-height: 32px;
            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
        .el-select {
          width: 100%;
        }
        .fitLine {
          margin: 0 5px;
        }
        .el-textarea {
          padding-top: 4px;
        }
      }
    }
  }
}
</style>
