<template>
  <div class="dashboard-box">
    <div ref="cardshowbox" class="show-box">
      <drawcard ref="drawcard" v-if="cardheight" :chartdata="tempitem.chartdata" :cardheight="cardheight" :isshow="true" />
    </div>
    <!-- 一倍图在ppt太模糊 为生成多倍图 隐藏一个放大的相同的盒子用来生成图片 -->
    <div ref="cardhidebox" class="hide-box">
      <drawcard v-if="cardheight" :chartdata="tempitem.chartdata" :cardheight="cardheight" @drawsuccess="drawsuccess" :isshow="false" />
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import drawcard from './drawcard'

export default {
  props: ['tempitem'],
  data() {
    return {
      cardheight: 0,
    }
  },
  components: { drawcard },
  watch: {},
  computed: {},
  created() {},
  methods: {
    drawsuccess() {
      const _this = this
      const pageYOffset = window.pageYOffset
      const pageXOffset = window.pageXOffset
      const opts = {
        background: '#FFFFFF',
        logging: false,
        scrollY: -pageYOffset,
        scrollX: -pageXOffset,
        useCORS: true,
        scale: 1,
      }
      setTimeout(() => {
        if (_this.tempitem.chartdata) {
          const dm = _this.$refs['cardhidebox']
          if (!dm) return
          html2canvas(dm, opts)
          html2canvas(dm, opts).then(canvas => {
            canvas.toBlob(blob => {
              //以时间戳作为文件名 区分不同文件
              const cardname = (_this.tempitem.definition && _this.tempitem.definition.name) || _this.tempitem.chartdata.cardinfo.name
              const filename = `${cardname}.png`
              //转换canvas图片数据格式为formData
              const file2 = new File([blob], filename, { type: 'image/png' })
              const formData = new FormData()
              formData.append('file', file2)
              _this.$emit('drawsuccess', file2)
            })
          })
        } else {
          _this.$emit('drawsuccess')
        }
      }, 100)
    },
  },
  mounted() {
    this.cardheight = this.$refs['cardshowbox'].offsetHeight
  },
}
</script>

<style lang="scss" scoped>
.dashboard-box {
  width: 100%;
  height: 100%;
  .show-box,
  .hide-box {
    width: 100%;
    height: 100%;
    padding: 0 15px;
  }

  .hide-box {
    position: absolute;
    left: -5999px;
    top: -5999px;
    transform: scale(2);
  }
}
</style>
<style lang="scss">
.dashboard-box {
  .hide-box {
    .set-select {
      opacity: 0;
    }
    i {
      transition: none !important;
      &.el-icon-arrow-down,
      &.el-icon-arrow-up {
        opacity: 0;
      }
    }
  }
}
</style>
