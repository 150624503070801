<template>
  <div class="titletemp" :class="titleset.settings.titleStyle">
    <div ref="maintitleref" class="main-title">
      {{ titleset.content.maintitle }}
    </div>
    <div v-if="titleset.settings.titleStyle === 'mainandsub'" class="sub-title">
      {{ titleset.content.subtitle }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'TitleTemp',
  props: ['tempitem'],
  data() {
    return {
      titleset: '',
    }
  },
  watch: {},
  created() {
    this.titleset = this.tempitem
    this.init()
  },
  methods: {
    init() {},
    exportdata() {
      let temp = this.titleset
      return temp
    },
  },
}
</script>

<style lang="scss" scoped>
.titletemp {
  line-height: 32px;
  display: flex;
  .main-title {
    background: #d03a2b;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .sub-title {
    color: #d03a2b;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 1.27%;
  }
  &.main {
    width: 73.3%;
    .main-title {
      width: 100%;
    }
  }
  &.mainandsub {
    .main-title {
      width: 31.94%;
    }
    .sub-title {
      flex: 1;
    }
  }
  &.catalogue {
    .main-title {
      width: 31.94%;
    }
  }
}
</style>
