<template>
  <div class="leftNav">
    <div class="nav-top dis_flex">
      <h3 class="wordellipsis">
        {{ pptData.name }}
        <el-tooltip class="item" effect="dark" content="切换报告" placement="top-start">
          <i @click="changeppt" class="iconfont iconqiehuan"></i>
        </el-tooltip>
      </h3>
    </div>
    <div class="left-tree">
      <myScroll>
        <div class="tree-box">
          <el-tree
            ref="treeList"
            v-if="leftMenu"
            :data="leftMenu"
            node-key="id"
            icon-class=""
            :default-expanded-keys="expandedid"
            :current-node-key="currentid"
            @node-drag-start="handleDragStart"
            @node-drag-enter="handleDragEnter"
            @node-drag-leave="handleDragLeave"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-drop="handleDrop"
            @node-click="debounce"
            @node-contextmenu="nodecontextmenu"
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :props="defaultProps"
          >
            <span class="slottree" slot-scope="{ node, data }" :class="{ notfolder: data.nodeType !== 'Folder', currentid: data.id == currentid }">
              <i></i><span>{{ data.idx }}. {{ node.label }}</span
              ><b :class="{ changeid: changePageIds.includes(data.id) }"></b>
            </span>
          </el-tree>
          <div class="editMenu" v-if="openeditMenu" :style="{ top: editMenuTop }">
            <ul>
              <li @click="editpage">编辑</li>
              <li v-if="!['Cover', 'Catalogue', 'BackCover'].includes(rightClickNode.slideType)" @click="delpage">删除</li>
            </ul>
          </div>
          <div v-if="$commonJs._point('BI_F0036')" class="addbtn dis_flex_center">
            <span @click="openAdd">+添加</span>
          </div>
        </div>
      </myScroll>
    </div>

    <el-dialog
      class="dialogaddpage thmemBottomMenu"
      title="添加页面"
      :visible.sync="dialogaddpage"
      width="600px"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="fileClose"
    >
      <div class="dialog-body">
        <el-form @submit.native.prevent="saveAdd">
          <el-form-item label="页面名称：" label-width="120px" size="small">
            <el-input v-model="addpagename" autocomplete="off" maxlength="20" placeholder="不超出20个字"></el-input>
          </el-form-item>
          <el-form-item label="页面类型：" label-width="120px">
            <el-radio v-model="slideType" label="Chart">图表页</el-radio>
            <el-radio v-model="slideType" label="Chapter">章节页</el-radio>
          </el-form-item>
          <el-form-item v-if="slideType === 'Chart'" label="标题样式：" label-width="120px">
            <el-radio v-model="titleStyle" label="main">标题</el-radio>
            <el-radio v-model="titleStyle" label="mainandsub">标题+副标题</el-radio>
          </el-form-item>
          <el-form-item v-if="slideType === 'Chart'" label="选择模板：" label-width="120px">
            <ul class="temps">
              <li
                v-for="(item, index) in templist"
                :key="index"
                @click="clicktemp(index)"
                :class="{ active: item.selected }"
                :style="{ background: item.img, backgroundSize: '100% 100%' }"
              >
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogaddpage = false">取 消</el-button>
        <el-button type="primary" size="small" @click="saveAdd">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="dialogaddpage thmemBottomMenu"
      title="编辑"
      :visible.sync="dialogedit"
      width="600px"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="fileClose"
    >
      <div class="dialog-body">
        <el-form @submit.native.prevent="saveEdit">
          <el-form-item label="页面名称：" label-width="120px" size="small">
            <el-input v-model="editname" autocomplete="off" maxlength="20" placeholder="不超出20个字"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogedit = false">取 消</el-button>
        <el-button type="primary" size="small" @click="saveEdit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import defaultData from './list/defaultData'
import reportJS from '@/views/Report/report.js'
import BI_config from '@/utils/config'
import { getpresentation, postpresentationslide, putpresentationslide, deletepresentationslide, postrecitifyorder } from '@/api/reportAPI'

// 切换页面判断是否有修改 如果有修改数据放到 changePgs
// 保存时 判断当前页面是否有修改 如果有修改放到 changePgs 然后循环 changePgs 执行保存事件
export default {
  name: 'leftNav',
  props: ['pptData', 'changePages'],
  data() {
    return {
      navData: null,
      leftMenu: null,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      expandedid: [],
      currentid: '', //当前页面id

      clickNode: '', //当前选中页面
      currentPage: '',
      rightClickNode: '', //右键选择页面
      openeditMenu: false,

      changePageIds: [], //修改页面集合

      dialogaddpage: false,
      addpagename: '',
      slideType: 'Chart',
      titleStyle: 'main',
      templist: [],
      dialogedit: false,
      editname: '',

      pptID: Number(this.$route.params.pptID),
      debounce: evt => this.$commonJs2.debounce(this.handleClick(evt), 1000),
    }
  },
  watch: {
    '$route.params.slideID': function() {
      this.currentid = this.$route.params.slideID
    },
    'changePages.length': function() {
      this.changePageIds = this.changePages.map(x => x.id)
    },
  },
  created() {
    this.leftMenu = this.pptData.slides.map((x, i) => {
      x.idx = i + 1
      return x
    })
    this.init()
  },
  methods: {
    init() {
      this.currentid = this.$route.params?.slideID
      this.clickNode = this.leftMenu.find(x => x.id == this.currentid)
      this.templist = [
        { name: '', temp: 'temp1', selected: true, img: `url(${require('@/assets/images/ppttemp/temp1.png')})` },
        { name: '', temp: 'temp2', selected: false, img: `url(${require('@/assets/images/ppttemp/temp2.png')})` },
        { name: '', temp: 'temp3', selected: false, img: `url(${require('@/assets/images/ppttemp/temp3.png')})` },
        { name: '', temp: 'temp4', selected: false, img: `url(${require('@/assets/images/ppttemp/temp4.png')})` },
        { name: '', temp: 'temp5', selected: false, img: `url(${require('@/assets/images/ppttemp/temp5.png')})` },
        { name: '', temp: 'temp6', selected: false, img: `url(${require('@/assets/images/ppttemp/temp6.png')})` },
        { name: '', temp: 'temp7', selected: false, img: `url(${require('@/assets/images/ppttemp/temp7.png')})` },
      ]
    },
    changeppt() {
      this.$router.push({ path: `${BI_config.dashboardProxy}/ReportList/${this.$route.params.projectID}` })
    },
    handleDragStart() {},
    handleDragEnter() {},
    handleDragLeave() {},
    handleDragOver() {},
    handleDragEnd() {
      const slideIDs = this.leftMenu.map(x => x.id)
      const param = {
        presentationID: this.pptID,
        slideIDs,
      }
      postrecitifyorder(param).then(() => {
        this.leftMenu = this.leftMenu.map((x, i) => {
          x.idx = i + 1
          return x
        })
      })
    },
    handleDrop() {},
    //右键
    nodecontextmenu(event, dropNode) {
      if (!this.$commonJs._point('BI_F0036')) return
      this.openeditMenu = false
      this.openeditMenu = true
      this.editMenuTop = event.clientY - 10 + 'px'
      this.rightClickNode = dropNode
    },
    allowDrop(draggingNode, dropNode, type) {
      return type !== 'inner'
    },
    allowDrag() {
      return true
    },
    handleClick(item) {
      const _this = this
      if (this.currentid == item.id) return
      this.currentid = item.id
      const path = `${BI_config.dashboardProxy}/reportPages/${this.$route.params.projectID}/${this.$route.params.pptID}/${item.id}`
      _this.$router.push({ path: path })
      _this.clickNode = item
    },
    handleClick2(item) {
      const _this = this
      if (this.currentid == item.id) return
      //切换页面 校验是否有编辑

      if (this.clickNode && this.isslidechange()) {
        this.$confirm('编辑内容尚未保存, 确定离开当前页面?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
          customClass: 'theme_message',
        })
          .then(() => {
            _this.currentid = item.id
            const path = `${BI_config.dashboardProxy}/reportPages/${_this.$route.params.projectID}/${_this.$route.params.pptID}/${item.id}`
            _this.$router.push({ path: path })
            _this.clickNode = item
          })
          .catch(() => {})
      } else {
        this.currentid = item.id
        const path = `${BI_config.dashboardProxy}/reportPages/${this.$route.params.projectID}/${this.$route.params.pptID}/${item.id}`
        this.$router.push({ path: path })
        this.clickNode = item
      }
    },
    isslidechange() {
      this.$eventBus.$emit('propcurrentPage')
      let ischange = false
      this.clickNode.elements.forEach((x, i) => {
        if (x.elementType === 'Chart') {
          let def = reportJS.initcarddefinition(this.currentPage.elements[i], this.currentPage.elements[i].definition)
          const xdef = x.definition ? x.definition : ''
          def = def ? def : ''
          if (xdef !== def) {
            ischange = true
          }
        } else {
          if (x.content !== JSON.stringify(this.currentPage.elements[i].content)) {
            ischange = true
          }
        }
      })
      return ischange
    },

    openAdd() {
      this.dialogaddpage = true
    },
    editpage() {
      this.dialogedit = true
      this.editname = this.rightClickNode.name
    },
    saveEdit() {
      let slide = this.leftMenu.find(x => x.id === this.rightClickNode.id)
      slide.name = this.editname
      const param = {
        presentationID: this.pptID,
        slideID: this.rightClickNode.id,
        ...slide,
      }
      putpresentationslide(param).then(v => {
        this.dialogedit = false
        this.updataMenu()
      })
    },
    delpage() {
      this.$confirm('删除该页面, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        customClass: 'theme_message',
      })
        .then(() => {
          const param = {
            presentationID: this.pptID,
            slideID: this.rightClickNode.id,
          }
          //删除时 获取当前页面的上一个页面 删除后跳转到上一个页面
          const idlist = this.leftMenu.map(x => x.id)
          const delidx = idlist.indexOf(this.rightClickNode.id)
          const lastidx = delidx - 1 >= 0 ? delidx - 1 : 0
          const lastslide = this.leftMenu[lastidx]
          deletepresentationslide(param).then(() => {
            this.updataMenu()
            this.handleClick(lastslide)
          })
        })
        .catch(() => {})
    },
    clicktemp(idx) {
      this.templist.forEach((x, i) => {
        if (i == idx) {
          x.selected = true
        } else {
          x.selected = false
        }
      })
    },
    fileClose() {
      this.dialogaddpage = false
      this.addpagename = ''
      this.slideType = 'Chart'
      this.titleStyle = 'main'
      this.templist.forEach((x, i) => {
        if (i == 0) {
          x.selected = true
        } else {
          x.selected = false
        }
      })
    },
    saveAdd() {
      if (!this.addpagename && this.addpagename !== 0) {
        this.$message.error('页面名称不能为空')
        return
      }

      let param = {}
      if (this.slideType === 'Chart') {
        const temp = this.templist.find(x => x.selected)
        let selectedtemp = JSON.parse(JSON.stringify(defaultData.initialChart[temp.temp]))

        selectedtemp.settings = JSON.stringify(selectedtemp.settings)
        selectedtemp.name = this.addpagename
        selectedtemp.elements = selectedtemp.elements.map(ele => {
          ele.definition = ele.definition ? JSON.stringify(ele.definition) : ele.definition
          ele.content = ele.content ? JSON.stringify(ele.content) : ele.content
          if (ele.elementType === 'Title') {
            ele.settings.titleStyle = this.titleStyle
          }
          ele.settings = ele.settings ? JSON.stringify(ele.settings) : ele.settings
          return ele
        })

        param = {
          presentationID: this.pptID,
          ...selectedtemp,
        }
      } else {
        let initialChapter = JSON.parse(JSON.stringify(defaultData.initialChapter))

        initialChapter.settings = JSON.stringify(initialChapter.settings)
        initialChapter.name = this.addpagename
        initialChapter.elements = initialChapter.elements.map(ele => {
          ele.definition = ele.definition ? JSON.stringify(ele.definition) : ele.definition
          ele.content = ele.content ? JSON.stringify(ele.content) : ele.content
          ele.settings = ele.settings ? JSON.stringify(ele.settings) : ele.settings
          return ele
        })
        param = {
          presentationID: this.pptID,
          ...initialChapter,
        }
      }

      postpresentationslide(param).then(v => {
        this.dialogaddpage = false
        this.updataMenu(v.data)
      })
    },
    async updataMenu(slideID) {
      const pptres = await getpresentation(this.$route.params.pptID)
      this.leftMenu = pptres.data.slides
      this.$emit('change-ppt', pptres.data)
      if (slideID) {
        //跳转到新增页
        const item = this.leftMenu.find(x => x.id === slideID)
        this.handleClick(item)
      }
    },
  },
  mounted() {
    document.addEventListener('click', e => {
      if (e.target.className != 'editMenu') {
        this.openeditMenu = false
      }
    })
    this.changePageIds = this.changePages.map(x => x.id)
    this.$eventBus.$on('getcurrentPage', item => {
      this.currentPage = item
    })

    // 离开当前路由
  },
  destroyed() {
    this.$eventBus.$off('getcurrentPage')
  },
}
</script>

<style lang="scss" scoped>
.leftNav {
  .nav-top {
    height: 60px;
    background: #ececec;
    h3 {
      max-width: 210px;
      line-height: 24px;
      padding-left: 35px;
      i {
        position: absolute;
        cursor: pointer;
        right: 20px;
        font-size: 16px;
      }
    }
  }
  .left-tree {
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    .tree-box {
      min-height: 100%;
      padding-bottom: 100px;
      position: relative;
    }
    .el-tree {
      padding-top: 10px;
      padding-left: 10px;
      @include theme_bg1($theme-light);
    }
    .addbtn {
      width: 250px;
      position: fixed;
      bottom: 0;
      left: 0;
      @include theme_bg1($theme-light);
      box-shadow: 0 0 8px 0 rgba(136, 152, 170, 0.15);
      padding: 20px;
      text-align: center;
      transition: 0.3s ease-in-out;
      span {
        display: block;
        border-radius: 22px;
        @include high_color1($highcolor-cheng);
        @include high_border1($highcolor-cheng);
        width: 100px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
      }
    }
  }
}
.editMenu {
  position: fixed;
  left: 120px;
  width: 120px;
  padding: 10px;
  z-index: 9;
  border-radius: 6px;
  @include theme_bg1($theme-light);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px 0 rgba(136, 152, 170, 0.15);
  ul {
    li {
      font-size: 14px;
      @include theme_color2($theme-light);
      cursor: pointer;
      line-height: 24px;
      display: flex;
      align-items: center;
      i {
        color: #adb5bd;
        font-size: 12px;
        margin-right: 5px;
      }
      &:hover {
        @include high_color1($highcolor-cheng);
      }
    }
  }
}

.dialogaddpage {
  .dialog-body {
    min-height: 60px;
  }
  .el-form {
    .el-form-item {
      margin-bottom: 10px;
      .el-radio {
        @include theme_color2($theme-dark);
      }
    }
    .el-input {
      width: 300px;
    }
  }
  .temps {
    overflow: hidden;
    li {
      float: left;
      width: calc(33.3% - 10px);
      height: 70px;
      margin-right: 10px;
      margin-bottom: 15px;
      border: 1px solid #fff;
      cursor: pointer;
      &.active {
        @include high_border1($highcolor-cheng);
      }
    }
  }
}
</style>

<style lang="scss">
.leftNav {
  .el-tree {
    .el-tree-node {
      .el-tree-node__content {
        height: 34px;
        line-height: 34px;
        background: none;
        .el-tree-node__expand-icon {
          display: none;
        }
      }
      .currentid {
        span {
          font-size: 14px;
          @include high_color1($highcolor-cheng);
          font-weight: bold;
        }
      }
      &.is-expanded {
        .el-tree-node__content {
          .slottree {
            .el-icon-caret-right:before {
              content: '\e790';
            }
          }
        }
      }
    }
    .el-tree-node__children {
      .el-tree-node {
        .el-tree-node__content {
          height: 34px;
          line-height: 34px;
          &:hover,
          &:focus {
            @include high_color1($highcolor-cheng);
            .el-tree-node__label {
              @include high_color1($highcolor-cheng);
            }
          }
          .el-tree-node__label {
            @include theme_color2($theme-light);
            font-weight: normal;
          }
        }
        &.is-current,
        &.is-checked {
          .el-tree-node__content {
            .el-tree-node__label {
              @include high_color1($highcolor-cheng);
            }
          }
        }
      }
    }
    .slottree {
      display: flex;
      align-items: center;
      width: 100%;
      b {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 7px;
        &.changeid {
          @include high_bg1($highcolor-cheng);
        }
      }
      span {
        display: inline-block;
        width: calc(100% - 40px);
        font-size: 14px;
        @include theme_color2($theme-light);
        font-weight: bold;
        position: relative;
      }
      i {
        display: inline-block;
        width: 24px;
        height: 20px;
        padding: 6px;
        font-size: 14px;
        color: #d1d1d1;
      }
      &.notfolder {
        span {
          font-weight: normal;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
