<template>
  <div class="imagetemp">
    <img :src="tempitem.content.imgurl" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Imagetemp',
  props: ['tempitem'],
  data() {
    return {}
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    exportdata() {
      let temp = JSON.parse(JSON.stringify(this.tempitem))
      return temp
    },
  },
}
</script>
<style lang="scss" scoped>
.imagetemp {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
