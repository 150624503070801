import store from '@/store'
import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
import cardinitMark from '@/views/Report/pages/pageItem/modules/dashboard/cardinitMark.js'
import { cardSearch } from '@/api/apiV2_dashboard'
import Table from '@/components/tables/tableHead'
import { allColor } from '@/datajs/static.js'
const dashbdjs = {
  gettimeRange(_this, em, slot, iscontrast) {
    let emit = JSON.parse(JSON.stringify(em))
    if (emit) {
      let req = emit.item.reqObj
      em.item.timeRange = emit.v2time
      _this.$set(em.item, 'timeRange', emit.v2time)
      let isRelative = emit.v2time[0].mode
      let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, emit, isRelative, iscontrast)
      req.injectedArguments.timeRange = timeRan

      let contrast = req.injectedArguments.contrastType ? req.injectedArguments.contrastType : req.contrastType
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      req.injectedArguments.contrastType = contrast

      _this.$set(_this.carddata, 'reqObj', req)
      _this.$set(_this.carddata, 'timeRange', timeRan)
      dashbdjs.searchchange(_this, emit.item, req, slot, contrast)
    }
  },
  searchchange: (_this, item, req, contrastitem, type) => {
    const bookmark = item.cardinfo
    if (bookmark.category === 'combination') {
      cardinitMark.searchchange(_this, contrastitem, type)
    } else {
      dashbdjs.searchchangeV2(_this, item, req, contrastitem, type)
    }
  },
  historychange(_this, item, req, history) {
    const contrastitem = req.contrastType
    const type = item.otherset.slot
    dashbdjs.searchchangeV2(_this, item, req, contrastitem, type, history)
  },
  searchchangeV2(_this, item, req, contrastitem, type, history) {
    const bookmark = item.cardinfo
    let definition = JSON.parse(bookmark.definition)
    let temp = ''
    let p2 = {}
    temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    p2 = definition
    p2.settings = req.settings
    _this.carddata.loading = true
    _this.carddata.option = null

    let inject = req.injectedArguments

    if (type == 'contrast' || type == 'Contrast') {
      let contrast = req.contrastType
      let v2item = {}
      v2item.v2time = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          var timeRa = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
          v2item.v2time = initMarkV2.getV2timeRan(_this, req, timeRa)
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, v2item)
      inject.timeRange = timeRan
      inject.contrastType = contrast
      _this.carddata.timeRange = timeRan
      p2.ContrastType = contrast
    } else if (type == 'slot') {
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      let contrast = inject.contrastType ? inject.contrastType : req.contrastType
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      inject.contrastType = contrast
      inject.selectedGroups = [{ code: 'eventTime', type: 'date', category: 3, value: contrastitem, text: '事件发生时间' }]
      p2.ContrastType = contrast
    } else if (type == 'mallid') {
      p2.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
    } else if (type == 'refresh') {
      if (inject.timeRange || req.parameter.timeRanges) {
        inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      }
    }
    if (inject && inject.timeRange) {
      req.timeRange = inject.timeRange
    }
    _this.$set(_this.carddata, 'reqObj', req)
    if (inject.timeRange) {
      _this.$set(_this.carddata, 'timeRange', inject.timeRange)
    }
    dashbdjs.getSearch2(_this, item, temp, p2, 'search', req, type, history)
  },

  async getSearch2(_this, item, temp, p, search, req, refresh, history) {
    _this.carddata.loading = true
    let carddef = JSON.parse(item.cardinfo.definition)
    let param = {
      ...carddef.parameter,
    }
    if (search && refresh == 'refresh') {
      param.forceRefresh = true
      let time = []
      if (p.parameter.timeRanges) {
        time = req.timeRange
      }
      if (temp == 'Template') {
        const components = p.parameter.components
        const tm = components.find(x => {
          return x.type == 'TimePicker'
        })
        if (tm) {
          time = req.timeRange
        }
      }
      if (time && time.length > 0) {
        param.timeRanges = time
      }
    }
    if (search && refresh != 'refresh') {
      if (req.injectedArguments.contrastType) {
        param.contrastType = req.injectedArguments.contrastType
      }
      if (req.injectedArguments.timeRange && req.injectedArguments.timeRange[0].mode == 'Relative') {
        param.timeRanges = req.injectedArguments.timeRange
      } else if (req.injectedArguments.timeRange) {
        param.timeRanges = req.injectedArguments.timeRange
      }
      if (param.contrastType != 'Contrast' && param.timeRanges) {
        param.timeRanges = [param.timeRanges[0]]
      }
      if (req.injectedArguments.selectedGroups) {
        param.groups = p.parameter.groups
        param.groups.forEach(g => {
          if (g.code == 'time' && g.mode == 'Normal') {
            g.preset = req.injectedArguments.selectedGroups[0].value
            g.ranges = null
          }
        })
      }
    }
    if (history) {
      param.enableHistory = history
    }
    param = Object.assign(param)
    //更新定义
    carddef.parameter = param
    carddef.settings = req.settings
    _this.carddata.cardinfo.definition = JSON.stringify(carddef)

    let hotmapdata = ['Behavior', 'behavior'].includes(temp) ? await dashbdjs.hotMap(_this, 0, p.parameter) : '1'
    cardSearch(item.searchCode, param)
      .then(async d => {
        _this.carddata['injectsearchparam'] = param
        //2.0 获取数据 直接通过筛选 拿到筛选数据
        // 如果是自定义分析 search 请求状态码是 201 继续请求 直到 200或者 返回错误
        if (temp == 'Advance') {
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              initMarkV2.searchEnd(_this, p, coderes, param, temp, hotmapdata)
            }
          } else if (d.code == '200') {
            initMarkV2.searchEnd(_this, p, d, param, temp, hotmapdata)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              initMarkV2.recurrence(_this, searchRes, d, p, idx, temp, hotmapdata, searchtimes)
            } else if (searchRes.code == '200') {
              d = searchRes
              initMarkV2.searchEnd(_this, p, d, param, temp, hotmapdata)
            }
          } else if (coderes && coderes.code == '200') {
            d = coderes
            initMarkV2.searchEnd(_this, p, d, param, temp, hotmapdata)
          }
        } else {
          dashbdjs.searchEnd(_this, p, d, param, temp, hotmapdata)
        }
      })
      .catch(err => {
        console.error(err)
        if (_this.carddata) _this.carddata.loading = false
      })
  },

  searchEnd(_this, p, d, param, temp, hotmapdata) {
    let actualLength = d.data.rows.length
    d.data.rows = d.data.rows.slice(0, 10000)
    let result = initMarkV2.getFilterData2(d.data, _this.carddata)
    result.actualLength = actualLength
    if (_this.carddata) {
      let option = null
      if (result.columns.length == 0 || result.rows.length == 0) {
        option = { noData: true }
      } else {
        let opts = {
          ...p.settings,
          analysisType: temp,
        }
        let tableObj = new Table({
          opts,
          columns: result.columns,
          result: result.rows,
        })
        //Table 内部修改了rows 需要同步更新d1
        result.rows = tableObj.params.result
        const tableObjdata = tableObj.init()
        option = {}
        option.noData = false
        _this.carddata['rawdata'] = result
        _this.carddata['orginResult'] = result
        _this.carddata['tableObj'] = tableObj
        _this.carddata['inittableObj'] = tableObjdata
        _this.carddata['resizedidx'] = 0
        _this.carddata['hotmapdata'] = hotmapdata
        _this.carddata['settings'] = p.settings
        if (opts.type === 'card' && option && option.arr.length > 0) {
          _this.$set(_this.carddata, 'curcard', option.arr[0])
          _this.$set(_this.carddata, 'curcardlabel', option.arr[0].label)
        }
      }
      option.color = dashbdjs.initChartColor(_this.carddata)
      _this.$set(_this.carddata, 'option', option)
      _this.carddata.loading = false
      const cardinfos = {
        index: 1,
        cardkey: _this.carddata.cardinfos.cardkey + 1,
        cardresizekey: _this.carddata.cardinfos.cardresizekey,
        parameter: param,
        settings: p.settings,
        result,
        hotmapdata: '',
        projectopts: {
          basicInfo: store.state.basicInfo,
          theme: store.state.basicInfo.project.theme,
          mainColor: store.state.basicInfo.project.mainColor,
          pageunit: store.state.basicInfo.project.axisUnit,
          contrastTrendColor: store.state.basicInfo.project.contrastTrendColor,
          projectMalls: store.state.projectMalls,
          weathers: store.state.weathers,
          festivals: store.state.festivals,
        },
        cardopts: {
          searchCode: _this.carddata.searchCode,
          category: temp,
        },
      }
      _this.$set(_this.carddata, 'cardinfos', cardinfos)
    }
  },
  initChartColor(cur) {
    let mainColoridx = 0
    let mainColor = null
    let chartColoridx = 0
    mainColoridx = cur.rdMainColor != null && cur.rdMainColor != '0' ? cur.rdMainColor : store.state.basicInfo.project.mainColor
    mainColor = cur.rdMainColor > 0 ? allColor['color' + (mainColoridx - 1)] : allColor['color' + mainColoridx]
    chartColoridx = cur.rdChartColor != null ? cur.rdChartColor : 0

    return mainColor[chartColoridx]
  },
  async hotMap(_this, idx, searchParam) {
    let mapData = ''
    let event = searchParam.events
    let group = searchParam.groups
    let filter = searchParam.filter
    if (group && group.length == 2 && filter && filter.conditions.length == 2) {
      let conditions = filter.conditions
      let mall = filter.conditions.filter(x => {
        return x.code == '_mall_id' && x.operator == '=' && x.values.length == 1
      })[0]
      let floor = filter.conditions.filter(x => {
        return x.code == '_floor_id' && x.operator == '=' && x.values.length == 1
      })[0]
      if (
        event.length == 1 &&
        group.length == 2 &&
        ['_shop_name', '_shop_id'].includes(group[0].code) &&
        ['_shop_name', '_shop_id'].includes(group[1].code) &&
        conditions.length == 2 &&
        filter.operator == 'and' &&
        mall &&
        floor
      ) {
        let p = {
          mallID: mall.values[0],
          floorID: floor.values[0],
        }
        await getMallMap(p).then(v => {
          mapData = v.data
        })
      } else {
        mapData = 1
      }
    } else {
      mapData = 1
    }
    return mapData
  },
}

export default dashbdjs
