import store from '@/store'
import { cardSearch } from '@/api/apiV2_dashboard'

import commonJs from '@/script/common.js'
// 多卡组合
let cardinitMark = {
  searchchange(_this, contrastitem, type) {
    _this.carddata.reqObj.cardlist.forEach(x => {
      x.option = null
    })
    _this.carddata.loading = true

    const chart = _this.carddata
    const reqObj = chart.reqObj
    reqObj.cardlist.forEach((card, cardidx) => {
      if (card.cardtype === 'word') {
        card.option = card.settings.styleSet.wordvalues
        cardinitMark.isloading(_this)
      } else {
        let p = card.reqObj
        let temp = card.bookmark.category.slice(0, 1).toUpperCase() + card.bookmark.category.slice(1)

        if (type == 'contrast' || type == 'Contrast') {
          let contrast = reqObj.contrastType
          let v2item = {}
          v2item.v2time = reqObj.parameter.timeRanges
          switch (contrast) {
            case 'nocontrast':
              contrast = 'Current'
              break
            case 'contrast':
              contrast = 'Contrast'
              var timeRa = reqObj.parameter.timeRanges
              v2item.v2time = cardinitMark.getV2timeRan(_this, reqObj, timeRa)
              break
            case 'last':
              contrast = 'Last'
              break
            case 'circle':
              contrast = 'Circle'
              break
            case 'lastAndCircle':
              contrast = 'LastAndCircle'
              break
          }
          if (contrast != 'Contrast') {
            v2item.v2time = [v2item.v2time[0]]
          }
          let timeRan = cardinitMark.getContrastTimeV2(_this, reqObj, v2item)

          chart.timeRange = timeRan
          reqObj.timeRange = timeRan
          reqObj.parameter.timeRanges = timeRan
          p.parameter.contrastType = contrast

          cardinitMark.getSearch(_this, cardidx, temp, p, card.bookmark, '', '', type)
        } else {
          cardinitMark.getSearch(_this, cardidx, temp, p, card.bookmark, '', '', type)
        }
      }
    })
  },
  async getSearch(_this, cardidx, temp, p, card, isinit, time, refresh) {
    const reqObj = _this.carddata.reqObj
    let timerang = time ? time : reqObj.parameter.timeRanges
    let param = JSON.parse(JSON.stringify(p.parameter))
    if (card.category == 'template') {
      let timecomp = param.components.find(x => {
        return x.type === 'TimePicker'
      })
      if (timecomp) {
        timecomp.timeRange = timerang[0]
      }
    }

    if (refresh == 'refresh') {
      param.forceRefresh = true
    }
    if (param.timeRanges) {
      param.timeRanges = reqObj.parameter.timeRanges
      if (param.contrastType) {
        const contrastType = reqObj.contrastType
        param.contrastType = contrastType === 'nocontrast' ? 'Current' : contrastType
      }
    }
    if (reqObj.injectedArguments && reqObj.injectedArguments.prefilter) {
      // 过滤条件
      param.prefilter = reqObj.injectedArguments.prefilter
    }
    cardSearch(card.category, param)
      .then(async res => {
        if (temp == 'Advance') {
          let d = res
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              cardinitMark.searchEnd(_this, cardidx, temp, p, card, coderes.data, '', isinit, param)
            }
          } else if (d.code == '200') {
            cardinitMark.searchEnd(_this, cardidx, temp, p, card, d.data, '', isinit, param)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              cardinitMark.recurrence(_this, searchRes, d, cardidx, temp, p, card, isinit, searchtimes, param)
            } else if (searchRes.code == '200') {
              d = searchRes
              cardinitMark.searchEnd(_this, cardidx, temp, p, card, d.data, '', isinit, param)
            }
          }
        } else {
          cardinitMark.searchEnd(_this, cardidx, temp, p, card, res.data, '', isinit, param)
        }
      })
      .catch(() => {
        if (_this.carddata) _this.carddata.loading = false
      })
  },
  async recurrence(_this, searchRes, d, cardidx, temp, p, card, isinit, searchtimes, param) {
    if (searchtimes >= 90) {
      _this.$alert(`查询超时`, '提示', {
        confirmButtonText: '确定',
      })
      return
    }
    try {
      searchRes = await getAdvanceSearch2(d.data.requestID)
    } catch (e) {}
    if (searchRes && searchRes.code == '200') {
      d = searchRes
      cardinitMark.searchEnd(_this, cardidx, temp, p, card, d.data, '', isinit, param)
    } else if (searchRes && searchRes.code == '202') {
      setTimeout(() => {
        searchtimes++
        return cardinitMark.recurrence(_this, searchRes, d, cardidx, temp, p, card, isinit, searchtimes, param)
      }, 2000)
    }
  },
  searchEnd(_this, cardidx, temp, p, card, res, hotmapdata, isinit, param) {
    const filterSet = _this.carddata.reqObj.cardlist[cardidx].reqObj.settings.filterSet
    let result = filterSet ? cardinitMark.getFilterData2(res, _this.carddata.reqObj.cardlist[cardidx]) : res
    let option = null
    let opts = {
      ...card.settings,
      analysisType: temp,
    }
    // let tableObj = new Table({
    //   opts,
    //   columns: result.columns,
    //   result: result.rows.slice(0, 10000),
    // })
    //Table 内部修改了rows 需要同步更新d1
    // result.rows = tableObj.params.result
    p.settings = card.settings
    // option = getOptionV2(_this, p, result, opts, tableObj.init(), hotmapdata, _this.chartData[idx].reqObj.cardlist[cardidx])
    option = {}
    option.noData = res.rows.length > 0 ? false : true

    // _this.carddata.reqObj.cardlist[cardidx]['data'] = result
    // _this.carddata.reqObj.cardlist[cardidx]['orginResult'] = result

    // _this.carddata.reqObj.cardlist[cardidx]['tableObj'] = tableObj
    // _this.carddata.reqObj.cardlist[cardidx]['inittableObj'] = tableObj.init()

    _this.carddata.reqObj.cardlist[cardidx]['resizedidx'] = 0
    _this.carddata.reqObj.cardlist[cardidx]['settings'] = p.settings
    const cardinfos = {
      index: cardidx,
      cardkey: _this.carddata.reqObj.cardlist[cardidx].cardinfos.cardkey + 1,
      cardresizekey: _this.carddata.reqObj.cardlist[cardidx].cardinfos.cardresizekey,
      parameter: param,
      settings: p.settings,
      result: res,
      hotmapdata,
      projectopts: {
        basicInfo: _this.basicInfo,
        theme: _this.basicInfo.project.theme,
        mainColor: _this.basicInfo.project.mainColor,
        pageunit: _this.basicInfo.project.axisUnit,
        contrastTrendColor: _this.basicInfo.project.contrastTrendColor,
        projectMalls: _this.projectMalls,
        weathers: store.state.weathers,
        festivals: store.state.festivals,
      },
      cardopts: {
        searchCode: _this.carddata.searchCode,
        category: temp,
      },
    }
    _this.$set(_this.carddata.reqObj.cardlist[cardidx], 'cardinfos', cardinfos)

    _this.$set(_this.carddata.reqObj.cardlist[cardidx], 'option', option)
    // if (opts.type === 'card' && option && option.arr.length > 0) {
    //   _this.$set(_this.carddata.reqObj.cardlist[cardidx], 'curcard', option.arr[0])
    //   _this.$set(_this.carddata.reqObj.cardlist[cardidx], 'curcardlabel', option.arr[0].label)
    // }
    cardinitMark.isloading(_this, isinit)
  },
  isloading(_this, isinit) {
    const noopt = _this.carddata.reqObj.cardlist.find(x => {
      return !x.option
    })
    if (!noopt) {
      _this.$set(_this.carddata, 'loading', false)
      _this.$set(_this.carddata, 'option', true)
      if (!isinit) {
        _this.carddata.cardkey++
      }
    }
  },
  getV2timeRan(_this, req, timeRange) {
    let time = timeRange
    let t1 = JSON.parse(JSON.stringify(time[0]))
    let t2 = {}
    if (t1.mode == 'Relative') {
      let showtime = commonJs.getshowdata(_this, t1)
      t2.start = showtime[0]
      t2.end = showtime[1]
      t2.mode = 'Absolute'
    } else {
      t2 = t1
    }
    const diff_days = _this.moment(t2.end).diff(_this.moment(t2.start), 'days')
    t2.end = _this
      .moment(t2.start)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    const date2 = _this
      .moment(t2.start)
      .subtract(diff_days + 1, 'days')
      .format('YYYY-MM-DD')
    t2.start = date2
    if (!time[1]) {
      time[1] = t2
    }
    return time
  },
  getContrastTimeV2: (_this, req, emit, isRelative, iscontrast) => {
    //如果修改的是对比时间，获取当前时间参数并修改对比时间
    //否则获取时间参数 如果有对比 第二个时间不修改否则计算对比时间
    if (iscontrast) {
      let newTime = req.parameter.timeRanges
      newTime[1] = emit.v2time[0]
      return newTime
    } else {
      let timeRange = emit && emit.v2time ? emit.v2time : req.parameter.timeRanges
      // let timeRange = req.parameter.timeRanges
      // const v2time = emit && emit.v2time
      let times = JSON.parse(JSON.stringify(timeRange))
      let newTime = []
      newTime.push(times[0])
      if (req.contrastType == 'contrast') {
        newTime.push(times[1])
      }
      return newTime
    }
  },
  getFilterData2: (d, chart) => {
    let copyres = _.cloneDeep(d)
    let filterSet = chart.reqObj.settings.filterSet
    const propsetGroup = chart.reqObj.settings?.propSet?.groups ?? []
    // let funnelarr = ['funnel', 'table_more']
    // if (funnelarr.includes(chart.reqObj.mode)) {
    //   return d
    // }

    let groups = copyres.columns.filter(x => {
      return x.usage == 'Group'
    })
    let measures = copyres.columns.filter(x => {
      return x.usage == 'Measure'
    })
    let rows = copyres.rows.filter(x => {
      let s = ''
      let isUnknown = false
      groups.forEach((g, gidx) => {
        //隐藏未知
        if (filterSet.hideUnknown) {
          if (x[g.alias] == '-') {
            isUnknown = true
          }
        }
        if (gidx < groups.length - 1 && groups.length > 1) {
          s += x[g.alias] + '[|]'
        } else {
          s += x[g.alias]
        }
      })
      x.sys_gen_key = s
      // 指标类型 PERCENTAGE_DIRECT 的值 *0.01
      if (!x.per_direct) {
        copyres.columns.forEach(m => {
          if (m.displayType == 'PERCENTAGE_DIRECT') {
            x[m.alias] = x[m.alias] * 0.01
          }
        })
        x.per_direct = true
      }
      return x && !isUnknown
    })
    let filterdata = {}

    if (copyres.columns.length > 0) {
      let oldname = ''
      filterdata.columns = copyres.columns.filter(x => {
        if (x.usage === 'Group') {
          const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
          if (grp) x.title = grp.title
        }
        let targ = filterSet.columns.find(t => {
          if (t.fullAlias == x.fullAlias) {
            oldname = x.title
          }
          return t.fullAlias == x.fullAlias
        })
        let isshow = targ && (!targ.usage || targ.usage === 'Measure') ? targ.selected : true
        x.title = targ && (!targ.usage || targ.usage === 'Measure') ? targ.name : cardinitMark.reviseName(copyres, x)
        if (!targ) {
          let meatarg =
            filterSet &&
            filterSet.columns &&
            filterSet.columns.find(t => {
              return x.alias.includes(t.alias)
            })
          if (meatarg) {
            x.title = x.title.replace(oldname, meatarg.name)
          }
        }
        return isshow
      })

      //指标数值过滤开启时，根据指标start，end过滤数值
      if (filterSet.filterValue) {
        rows = rows.filter(x => {
          let istrue = true
          filterSet.columns.forEach(c => {
            let val =
              c.filterCode && x[c.filterCode]
                ? typeof x[c.filterCode] === 'number'
                  ? x[c.filterCode]
                  : x[c.filterCode] == '-'
                  ? '-'
                  : parseFloat(x[c.filterCode].replace(/[,]/g, ''))
                : x[c.alias]
            if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start <= c.end && val >= c.start && val <= c.end) {
            } else if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start > c.end && val >= c.start) {
            } else if (!c.start && c.start !== 0 && (c.end || c.end === 0) && val <= c.end) {
            } else if ((c.start || c.start === 0) && !c.end && c.end !== 0 && val >= c.start) {
            } else if (!c.start && c.start !== 0 && !c.end && c.end !== 0) {
            } else {
              istrue = false
            }
          })
          return istrue
        })
        filterdata.rows = rows
      } else {
        filterdata.rows = rows
      }

      // 漏斗
      if (filterdata.funnels) {
        let funnels = filterdata.funnels.map(x => {
          let itemres = cardinitMark.getFunnelSteps(x, chart.reqObj.settings)
          return itemres
        })
        filterdata.funnels = funnels
      }
    } else {
      filterdata = copyres
      filterdata.rows = rows
    }
    if (copyres.timeRanges) {
      filterdata.timeRanges = copyres.timeRanges
    }
    if (copyres.uniqueID) {
      filterdata.uniqueID = copyres.uniqueID
    }
    // 如果分组名设置了不可见
    const groupformatlist = propsetGroup.filter(x => x.groupformat && x.groupformat.length > 0)
    if (groupformatlist) {
      filterdata.rows.forEach(x => {
        groupformatlist.forEach(g => {
          g.groupformat.forEach(f => {
            x[g.alias] = x[g.alias].replace(f, '')
          })
        })
      })
    }
    return filterdata
  },
  getFunnelSteps(searchRes, settings) {
    const copyColumns = JSON.parse(JSON.stringify(searchRes.columns))
    let res = searchRes
    const propsetGroup = settings?.propSet?.groups ?? []

    let columns = copyColumns.map(x => {
      if (x.usage === 'Group') {
        const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
        if (grp) x.title = grp.title
      }
      return x
    })
    res.columns = columns
    return res
  },
  reviseName(d, column) {
    let name = column.title
    //是否同环比值
    d.columns.forEach(x => {
      if (x.alias + '_contrast' == column.alias) {
        name = '对比' + x.title
      }
      if (x.alias + '_last' == column.alias) {
        name = '去年同期' + x.title
      }
      if (x.alias + '_circle' == column.alias) {
        name = '上一周期' + x.title
      }
    })
    return name
  },
}

export default cardinitMark
