<template>
  <div class="reportPages">
    <leftNav v-if="pptData" :pptData="pptData" :changePages="changePages" @change-ppt="changeppt" :key="navkey" class="page-l" />
    <div class="page-r">
      <pptPage ref="pageref" v-if="pptData" :pptData="pptData" :changePages="changePages" @change-ppt="changeppt" @savepages="savepages" :key="pagekey" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import leftNav from '../leftNav'
import pptPage from './pptPage'
import { getpresentation } from '@/api/reportAPI'
import { putpresentationslide, postchartupload } from '@/api/reportAPI'
export default {
  data() {
    return {
      navkey: 0,
      pagekey: 0,
      pptData: null,
      changePages: [],
    }
  },
  components: { leftNav, pptPage },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
      system: state => state.system,
    }),
  },
  watch: {
    '$route.params.slideID': function() {
      this.pagekey++
    },
  },
  created() {
    this.$store.commit('setChangePPTPages', [])
    this.init()
  },
  methods: {
    async init() {
      if (!this.$route.params.slideID || !this.pptData) {
        const pptres = await getpresentation(this.$route.params.pptID)
        this.pptData = pptres.data
        if (!this.$route.params.slideID) {
          const slides = pptres.data.slides
          const path = this.$route.path
          this.$router.replace({ path: path + '/' + slides[0].id })
        }

        // 埋点
        this.$trackView({ system: this.system, module: '数据报告', pageName: '数据报告', path: this.$route.path })
      }
    },
    async changeppt(item) {
      if (item) {
        this.pptData = item
        this.navkey++
      } else {
        const pptres = await getpresentation(this.$route.params.pptID)
        this.pptData = pptres.data
        this.navkey++
      }
    },
    savepages() {
      if (this.changePages.length === 0) return
      const _this = this
      const loading = this.$loading({
        lock: true,
        text: '保存中… 请不要关闭该页面!',
        customClass: `customloading`,
        spinner: `cardloading loading${this.basicInfo.project.mainColor}`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.changePages.forEach((page, pageidx) => {
        let ele_len = 0
        page.elements.forEach((ele, i) => {
          if (ele.elementType === 'Chart') {
            const formData = new FormData()
            if (!ele.content.file) {
              ele_len++
              if (ele_len === page.elements.length) {
                _this.putslide(page, pageidx, loading)
              }
              return
            }
            formData.append('file', ele.content.file)
            postchartupload(formData).then(url => {
              page.elements[i].content.imgurl = url.data
              delete page.elements[i].content.file
              ele_len++
              if (ele_len === page.elements.length) {
                _this.putslide(page, pageidx, loading)
              }
            })
          } else {
            ele_len++
            if (ele_len === page.elements.length) {
              _this.putslide(page, pageidx, loading)
            }
          }
        })
      })
    },
    putslide(page, pageidx, loading) {
      let currentPage = this.$lo_.cloneDeep(page)
      currentPage.settings = JSON.stringify(currentPage.settings)
      currentPage.elements = page.elements.map((ele, eidx) => {
        let copyele = this.$lo_.cloneDeep(ele)
        if (ele.elementType === 'Chart') {
          if (ele.chartdata) {
            delete copyele.content.file
            delete copyele.chartdata
            let cardinfo = ele.chartdata.cardinfo
            let cardinfodef = JSON.parse(ele.chartdata.cardinfo.definition)
            if (cardinfodef) {
              cardinfodef.settings = ele.chartdata.reqObj.settings
              cardinfo.definition = JSON.stringify(cardinfodef)
            }
            copyele.definition = JSON.stringify(cardinfo)
          } else {
            if (copyele.definition) {
              copyele.definition = JSON.stringify(copyele.definition)
            }
          }
        }
        copyele.content = ele.content ? JSON.stringify(ele.content) : ele.content
        copyele.settings = copyele.settings ? JSON.stringify(copyele.settings) : copyele.settings
        return copyele
      })
      const param = {
        presentationID: Number(this.$route.params.pptID),
        slideID: page.id,
        ...currentPage,
      }
      putpresentationslide(param)
        .then(v => {
          // loading.close()
          // this.$emit('change-ppt')
          if (pageidx === this.changePages.length - 1) {
            loading.close()
            this.changePages = []
            this.changeppt()
            this.$store.commit('setChangePPTPages', [])
            this.$refs['pageref'].$refs['pptref'].changeCopys()
          }
        })
        .catch(err => {
          console.error(err)
          if (pageidx === this.changePages.length - 1) {
            loading.close()
            this.changePages = []
            this.changeppt()
            this.$store.commit('setChangePPTPages', [])
            this.$refs['pageref'].$refs['pptref'].changeCopys()
          }
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'reportPages' && to.name !== 'reportPages') {
      if (this.changePages.length > 0) {
        this.$confirm('有未保存的页面，确定离开?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
          customClass: 'theme_message',
        })
          .then(() => {
            next()
          })
          .catch(() => {
            next(false)
          })
      } else {
        next()
      }
    }
  },
  mounted() {
    // 页面有改动 存到 changePages
    this.$eventBus.$on('pageChangeBus', item => {
      let isnew = true
      this.changePages.forEach((page, i) => {
        if (page.id === item.id) {
          isnew = false
          this.changePages[i] = item
          this.$set(this.changePages, i, item)
        }
      })
      if (isnew) {
        this.changePages.push(item)
      }
      this.$store.commit('setChangePPTPages', this.changePages)
    })
  },
  destroyed() {
    this.$eventBus.$off('pageChangeBus')
  },
}
</script>

<style lang="scss" scoped>
.reportPages {
  margin-left: 250px;
  transition: 0.3s ease-in-out;
  .page-l {
    width: 250px;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    z-index: 99;
    @include theme_bg1($theme-light);
    transition: 0.3s ease-in-out;
  }
  .page-r {
    min-width: 1464px;
    background: #f1f4fa;
    padding: 0;
    padding-top: 80px;
    padding-bottom: 20px;
  }
}
</style>
