import { cardSearch, getMallMap, getAdvanceSearch2, getTemplateByCode, getCityWeather } from '@/api/apiV2_dashboard'
import Table from '@/components/tables/tableHead'
import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
// import { getOptionV2 } from '@/script/bookmarkV2/getOptionV2'
import { allColor } from '@/datajs/static.js'
import commonJs from '@/script/common.js'
import combinjs from '../modules/dashboard/combin'
import store from '@/store'

let initCard = {
  allColor: allColor,
  init(_this, card, newcard) {
    //初次加载卡片或者选择卡片 获取定义 发起请求 然后组装chartdata
    let chart = {}
    if (card.category === 'combination') {
      chart = combinjs.init(_this, card)
      combinjs.initSearch(_this, card, newcard, chart)
    } else {
      chart = initCard.initchart(card)
      initCard.initSearch(_this, card, newcard, chart)
    }
  },
  initchart(el) {
    let definition = JSON.parse(el.definition)
    let defcode = el.category
    let reqObj = JSON.parse(JSON.stringify(definition))
    reqObj.title = reqObj.settings.nameCN
    reqObj.mode = reqObj.settings.type
    reqObj.contrastType = reqObj.parameter.contrastType == 'Current' ? 'nocontrast' : reqObj.parameter.contrastType
    let timeRange = reqObj.parameter.timeRanges
    if (el.category == 'template') {
      const timeComp = definition.parameter.components.find(x => {
        return x.type == 'TimePicker'
      })
      timeRange = timeComp ? [timeComp.timeRange] : null
    }
    //高级分析是否有时间
    const hastime_advance = el.category == 'advance' ? getadvancetime(reqObj.parameter) : true

    reqObj.contrastType = reqObj.contrastType ? reqObj.contrastType.toLowerCase() : ''
    reqObj.injectedArguments = {
      conditionGroup: {
        conditions: [],
        operator: 'and',
      },
    }
    let themeSet = reqObj.settings.themeSet

    // 卡片主题设置 chartMainColor 为 0 跟随系统(_this.basicInfo.project.mainColor) 否则（themeSet.chartMainColor）
    // 项目主题mainColor 0
    const chartMainColor = (themeSet && parseInt(themeSet.chartMainColor)) || 0
    const categoryColor = (themeSet && parseInt(themeSet.categoryColor)) || 0
    let mainColoridx = chartMainColor > 0 ? chartMainColor : store.state.basicInfo.project.mainColor
    let mainColor = chartMainColor > 0 ? allColor['color' + (mainColoridx - 1)] : allColor['color' + mainColoridx]

    let chart = {
      id: el.id,
      chartkey: '-',
      code: el.code,
      searchCode: defcode,
      reqObj: reqObj,
      timeRange: timeRange && hastime_advance ? JSON.parse(JSON.stringify(timeRange)) : null,
      loading: false,
      option: '',
      otherset: {},
      version: '2.0',
      rdMainColor: chartMainColor,
      chartColorV2: mainColor[categoryColor],
      rdChartColor: categoryColor,
    }
    let getslot = []
    let slots = []
    if (reqObj.parameter.groups) {
      getslot = reqObj.parameter.groups.filter((g, slotidx) => {
        return g.code == 'time' && g.mode == 'Normal'
      })
      slots = getslot.length > 0 ? getslot[0].preset.split('_') : ''
    }
    chart.otherset.slot = getslot.length > 0 ? (slots.length > 1 ? slots[1] : slots[0]) : null

    return chart
  },
  async initSearch(_this, card, newcard, chart) {
    chart.loading = true
    chart.cardinfo = card
    let carddef = JSON.parse(card.definition)
    const p = carddef.parameter
    let temp = card.category.slice(0, 1).toUpperCase() + card.category.slice(1)

    let param = {
      ...carddef.parameter,
    }
    cardSearch(card.category, param)
      .then(d => {
        initCard.initres(_this, carddef, temp, d, chart)
      })
      .catch(err => {
        console.error(err)
        if (chart) chart.loading = false
      })
  },
  async initres(_this, carddef, temp, d, chart) {
    if (temp == 'Advance') {
      let coderes = ''
      if (d && d.code == '201') {
        coderes = await getAdvanceSearch2(d.data.requestID)
        if (coderes.code == '200') {
          initCard.searchEnd(_this, carddef, temp, d.data, chart)
        }
      } else if (d.code == '200') {
        initCard.searchEnd(_this, carddef, temp, d.data, chart)
      }
      if (coderes && coderes.code == '202') {
        let searchRes = ''
        searchRes = await getAdvanceSearch2(d.data.requestID)
        if (searchRes && searchRes.code == '202') {
          let searchtimes = 1
          initCard.recurrence(_this, searchRes, d, carddef, temp, card, searchtimes)
        } else if (searchRes.code == '200') {
          d = searchRes
          initCard.searchEnd(_this, carddef, temp, d.data, chart)
        }
      }
    } else {
      initCard.searchEnd(_this, carddef, temp, d.data, chart)
    }
  },
  async recurrence(_this, searchRes, d, carddef, temp, card, searchtimes) {
    if (searchtimes >= 90) {
      _this.$alert(`查询超时`, '提示', {
        confirmButtonText: '确定',
      })
      return
    }
    try {
      searchRes = await getAdvanceSearch2(d.data.requestID)
    } catch (e) {}
    if (searchRes && searchRes.code == '200') {
      d = searchRes
      initCard.searchEnd(_this, carddef, temp, d.data, chart)
    } else if (searchRes && searchRes.code == '202') {
      setTimeout(() => {
        searchtimes++
        return initCard.recurrence(_this, searchRes, d, carddef, temp, card, searchtimes)
      }, 2000)
    }
  },
  searchEnd(_this, defin, temp, d, chart) {
    let actualLength = d.rows.length
    d.rows = d.rows.slice(0, 10000)
    let result = initMarkV2.getFilterData2(d, chart)
    result.actualLength = actualLength
    if (chart) {
      chart.rawdata = d
      let option = null
      if (result.columns.length == 0 || result.rows.length == 0) {
        option = { noData: true }
      } else {
        let opts = {
          ...defin.settings,
          analysisType: temp,
        }
        let tableObj = new Table({
          opts,
          columns: result.columns,
          result: result.rows,
        })
        result.rows = tableObj.params.result
        const tableObjdata = tableObj.init()
        // option = getOptionV2(_this, defin, result, opts, tableObjdata, '', chart)
        option = {}
        // if (opts.type === 'card' && option && option.arr.length > 0) {
        //   _this.$set(chart, 'curcard', option.arr[0])
        //   _this.$set(chart, 'curcardlabel', option.arr[0].label)
        // }
        const cardinfos = {
          index: 1,
          cardkey: '1',
          cardresizekey: '1',
          parameter: defin.parameter,
          settings: defin.settings,
          result,
          hotmapdata: '',
          projectopts: {
            basicInfo: store.state.basicInfo,
            theme: store.state.basicInfo.project.theme,
            mainColor: store.state.basicInfo.project.mainColor,
            pageunit: store.state.basicInfo.project.axisUnit,
            contrastTrendColor: store.state.basicInfo.project.contrastTrendColor,
            projectMalls: store.state.projectMalls,
            weathers: store.state.weathers,
            festivals: store.state.festivals,
          },
          cardopts: {
            searchCode: chart.searchCode,
            category: temp,
          },
        }
        _this.$set(chart, 'cardinfos', cardinfos)
      }
      chart.option = option

      chart.loading = false
      _this.emitchart(chart)
    }
  },
}

function getadvancetime(parameter) {
  let hastime = false
  parameter.analyses.forEach(x => {
    if (!hastime && x.category === 'behavior') {
      hastime = true
    }
    if (!hastime && x.category === 'template') {
      const components = JSON.parse(x.definition).parameter.components
      const timeRanges = components.find(c => {
        return c.type === 'TimePicker'
      })
      if (timeRanges) hastime = true
    }
  })

  return hastime
}

export default initCard
