<template>
  <div class="charttemp dis_flex_center">
    <div v-if="!tempitem.definition && !tempitem.chartdata" class="empty">+ 选择卡片</div>
    <dashboard
      ref="card"
      v-if="tempitem.definition || tempitem.chartdata"
      v-loading="tempitem.definition && !tempitem.chartdata"
      :element-loading-spinner="'cardloading loading'"
      :tempitem="tempitem"
      @drawsuccess="drawsuccess"
    />
  </div>
</template>
<script>
import dashboard from './dashboard/dashboard'
import initCard from '../drawer/initCard'

export default {
  name: 'Charttemp',
  props: ['tempitem'],
  data() {
    return {}
  },
  components: { dashboard },
  watch: {},
  created() {
    this.init()
  },
  methods: {
    init() {
      // 初始化如果有定义
      if (this.tempitem.definition && !this.tempitem.chartdata) {
        initCard.init(this, this.tempitem.definition)
      }
    },
    emitchart(chart) {
      let edititem = JSON.parse(JSON.stringify(this.tempitem))
      this.$set(edititem, 'chartdata', chart)
      this.$set(edititem, 'itemkey', edititem.itemkey + 1)
      this.$emit('chart-change', edititem)
    },
    alltimechange(time) {
      this.$refs['card'].$refs['drawcard'].gettimeRange(time)
    },
    exportdata() {
      let temp = JSON.parse(JSON.stringify(this.tempitem))
      if (temp.chartdata) {
        let cardinfo = temp.chartdata.cardinfo
        let cardinfodef = JSON.parse(temp.chartdata.cardinfo.definition)
        if (cardinfodef) {
          cardinfodef.settings = this.tempitem.chartdata.reqObj.settings
          cardinfo.definition = JSON.stringify(cardinfodef)
        }
        temp.definition = cardinfo
      }
      delete temp.chartdata
      return temp
    },
    drawsuccess(file) {
      this.$emit('drawsuccess', file)
    },
  },
}
</script>

<style lang="scss" scoped>
.charttemp {
  border: 1px solid #ccc;
  .empty {
    font-size: 12px;
    color: #999;
  }
}
</style>
