<template>
  <div class="gridBar" v-if="carddata.reqObj">
    <div class="gridtitle">
      {{ carddata.reqObj.title || carddata.reqObj[0].displayTitle }}
    </div>
    <div class="gridoption clearfix">
      <div
        v-if="carddata.timeRange"
        class="set-time"
        :style="{
          'max-width': carddata.timeRange[1] && (carddata.reqObj.contrastType == 'contrast' || carddata.searchCode == 'contrast') ? '530px' : '370px',
        }"
      >
        <div class="timebox dis_flex" v-if="carddata.timeRange">
          <extDatetime
            :key="JSON.stringify(carddata.timeRange[0])"
            :proptimeidx="0"
            :propitemidx="0"
            :maxInterval="getTimePickerInterval(carddata, 0)"
            :propDate="carddata.timeRange"
            @timechange="time => gettimeRange(time, carddata, 0)"
            class="lefttime"
          />
          <el-select
            class="set-select"
            popper-class="thmemBottomMenu"
            v-if="carddata.reqObj.contrastType && !carddata.reqObj.top"
            v-model="carddata.reqObj.contrastType"
            size="mini"
            @change="searchchange('contrast')"
          >
            <el-option v-for="item in contrastoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <span class="contrastspan" v-if="carddata.searchCode == 'contrast'">对比</span>
          <extDatetime
            v-if="carddata.timeRange[1] && (carddata.reqObj.contrastType == 'contrast' || carddata.searchCode == 'contrast')"
            :key="JSON.stringify(carddata.timeRange[1]) + 0"
            :proptimeidx="1"
            :propitemidx="0"
            :maxInterval="getTimePickerInterval(carddata, 1)"
            :propDate="carddata.timeRange"
            @timechange="time => gettimeRange2(time, carddata, 0)"
            class="righttime"
          />
          <div class="set-select" v-if="carddata.otherset.slot && slotshowarr.includes(carddata.otherset.slot) && !carddata.reqObj.top">
            <el-select v-model="carddata.otherset.slot" popper-class="thmemBottomMenu" size="mini" @change="searchchange('slot')">
              <el-option v-for="item in slotoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="!carddata.timeRange" class="nodate"></div>
    </div>
  </div>
</template>
<script>
import dashbdjs from './dashboardjs'
export default {
  props: ['cardData', 'isshow'],
  data() {
    return {
      carddata: null,

      contrastoptions: [
        { value: 'nocontrast', label: '无对比' },
        { value: 'contrast', label: '对比' },
        { value: 'last', label: '同比' },
        { value: 'circle', label: '环比' },
        { value: 'lastandcircle', label: '同环比' },
      ],
      alltime: '',
      allslot: 'day',
      slotoptions: [
        { value: 'hour', label: '按小时' },
        { value: 'day', label: '按天' },
        { value: 'week', label: '按周' },
        { value: 'month', label: '按月' },
        { value: 'quarter', label: '按季度' },
      ],
      slotshowarr: ['hour', 'day', 'week', 'month', 'quarter'],
    }
  },
  created() {
    this.carddata = this.cardData
  },
  methods: {
    gettimeRange(time, el, idx) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '')
      } else {
        dashbdjs.gettimeRange(this, newitem, '')
      }
    },
    gettimeRange2(time, el, idx) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '', 'contrast')
      } else {
        dashbdjs.gettimeRange(this, newitem, '', 'contrast')
      }
    },
    getTimePickerInterval(chart, index) {
      if (!chart.timePickerIntervals) {
        return -1
      }
      let arrs = [...chart.timePickerIntervals]
      arrs.push(-1)
      arrs.push(-1)
      return arrs[index]
    },
    //刷新 对比 按天
    searchchange(type) {
      this.carddata.option = null

      this.$emit('searchchange', type)
    },
  },
  mounted() {
    if (this.isshow) {
      this.$eventBus.$on('HistorySearch', set => {
        this.carddata.option = null
        const param = this.carddata.reqObj.parameter
        const dategroup = param.groups.find(x => {
          return x.dataType === 'Date' && x.code === 'time'
        })
        const styleSet = this.carddata.reqObj.settings.styleSet
        const history = !param.paging && dategroup && styleSet.allDimValues
        dashbdjs.historychange(this, this.carddata, this.carddata.reqObj, history)
      })
    }
  },
  destroyed() {
    this.$eventBus.$off('HistorySearch')
  },
}
</script>

<style lang="scss">
.gridBar {
  .gridtitle {
    max-width: calc(100% - 50px);
    font-size: 16px;
    font-weight: bold;
    @include theme_color2($theme-light);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 10px;
    .wenhao {
      font-weight: normal;
      cursor: pointer;
    }
  }
  .gridoption {
    .set-time {
      float: left;
      text-align: left;
      width: calc(100% + 15px);
      height: 20px;
      line-height: 16px;
      padding-top: 5px;
      .timebox {
        flex-wrap: wrap;
        .hoverTime {
          position: absolute;
          top: 15px;
        }
      }
    }
    .set-select {
      width: 58px;
      flex-shrink: 0;
      border: none;
      .el-input {
        border: none;
        input {
          height: 16px;
          line-height: 16px;
          border: none !important;
          padding-left: 0;
          text-align: right;
          background: none;
        }
        .el-input__inner {
          color: #adb5bd !important;
          padding-right: 20px;
        }
        .el-input__suffix {
          top: 3px;
          .el-input__icon {
            width: 14px;
            font-size: 12px;
            line-height: 20px;
          }
        }
        &.is-focus {
          .el-input__suffix {
            top: -2px;
          }
        }
      }
    }
    .contrastspan {
      font-size: 12px;
      margin: 0 10px 0 18px;
      color: #adb5bd;
    }
    .nodate {
      width: 100%;
      height: 8px;
    }
  }
}
</style>
