import cardinitMark from '@/views/dashboard/carddashboard/cardinitMark'
import Table from '@/components/tables/tableHead'
import commonJs from '@/script/common.js'
// import { getOptionV2 } from '@/script/bookmarkV2/getOptionV2'
import store from '@/store'
import { Search2, cardSearch, getMallMap, getAdvanceSearch2, getTemplateByCode, getCityWeather } from '@/api/apiV2_dashboard'
import { allColor } from '@/datajs/static.js'

let combinjs = {
  init(_this, el, cardkey) {
    let definition = JSON.parse(el.definition)
    let reqObj = JSON.parse(JSON.stringify(definition))
    reqObj.content = el.content
    reqObj['setting'] = reqObj.settings
    reqObj.title = reqObj.settings.nameCN
    reqObj.cardGrid = []
    reqObj.cardlist = []

    reqObj.parameter.analyses.forEach((card, cardidx) => {
      let cardpos = card.position
      let cardposlist = {
        id: card.id,
        x: cardpos.x || cardpos.x == 0 ? cardpos.x : 4,
        y: cardpos.y || cardpos.y == 0 ? cardpos.y : 4,
        w: cardpos.row || cardpos.row == 0 ? cardpos.row : 4,
        h: cardpos.col || cardpos.col == 0 ? cardpos.col : 4,
        i: cardidx,
      }
      reqObj.cardGrid[cardidx] = cardposlist

      if (card.settings.type === 'word') {
        const cardchart = {
          index: cardidx,
          cardtype: card.settings.type,
          bookmark: card,
          settings: card.settings,
          option: card.words,
          chartkey: cardidx,
        }
        reqObj.cardlist[cardidx] = cardchart
      } else {
        let cardreqObj = JSON.parse(card.definition)
        let defcode = card.category
        let themeSet = card.settings.themeSet
        cardreqObj.settings = card.settings
        cardreqObj.mode = card.settings.type

        // 卡片主题设置 chartMainColor 为 0 跟随系统(_this.basicInfo.project.mainColor) 否则（themeSet.chartMainColor）
        // 项目主题 mainColor 0
        let cardmainColoridx = parseInt(themeSet.chartMainColor) > 0 ? parseInt(themeSet.chartMainColor) : store.state.basicInfo.project.mainColor
        let cardmainColor = parseInt(themeSet.chartMainColor) > 0 ? allColor['color' + (cardmainColoridx - 1)] : allColor['color' + cardmainColoridx]

        const cardchart = {
          index: cardidx,
          cardtype: card.settings.type,
          bookmark: card,
          settings: card.settings,
          option: '',
          searchCode: card.category,
          reqObj: cardreqObj,
          rdMainColor: parseInt(themeSet.chartMainColor),
          chartColorV2: cardmainColor[parseInt(themeSet.categoryColor)],
          rdChartColor: parseInt(themeSet.categoryColor),
          version: '2.0',
          chartkey: cardidx,
        }
        reqObj.cardlist[cardidx] = cardchart
      }
    })
    reqObj.contrastType =
      reqObj.parameter.contrastType == 'Current' || !reqObj.parameter.contrastType ? 'nocontrast' : reqObj.parameter.contrastType.toLowerCase()
    let timeRange = reqObj.parameter.timeRanges
    reqObj.injectedArguments = {
      conditionGroup: {
        conditions: [],
        operator: 'and',
      },
    }
    let chart = {
      id: el.id,
      lock: el.lock,
      cardkey: cardkey ? cardkey : 0,
      mapkey: '-',
      code: el.code,
      searchCode: el.category,
      reqObj: reqObj,
      option: null,
      timeRange: timeRange,
      loading: true,
      otherset: {},
      version: el.version,
    }

    let getslot = []
    let slots = []
    if (reqObj.parameter.groups) {
      getslot = reqObj.parameter.groups.filter((g, slotidx) => {
        return g.code == 'time' && g.mode == 'Normal' && slotidx == 0
      })
      slots = getslot.length > 0 ? getslot[0].preset.split('_') : ''
    }
    chart.otherset.slot = getslot.length > 0 ? (slots.length > 1 ? slots[1] : slots[0]) : null

    return chart
  },
  async initSearch(_this, card, newcard, chart) {
    chart.cardinfo = card
    let definition = JSON.parse(card.definition)
    definition.parameter.analyses.forEach((c, cardidx) => {
      if (c.settings.type === 'word') {
        chart.reqObj.cardlist[cardidx].option = chart.reqObj.cardlist[cardidx].settings.styleSet.wordvalues
        // cardinitMark.isloading(_this, index, isinit)
      } else {
        let carddef = JSON.parse(c.definition)
        let p = carddef
        let temp = c.category.slice(0, 1).toUpperCase() + c.category.slice(1)
        combinjs.getSearch(_this, chart, c, cardidx, temp, p)
      }
    })
  },
  getSearch(_this, chart, card, cardidx, temp, p, time = '', refresh = '') {
    const reqObj = chart.reqObj
    let timerang = time ? time : reqObj.parameter.timeRanges
    let param = JSON.parse(JSON.stringify(p.parameter))
    if (card.category == 'template') {
      let timecomp = param.components.find(x => {
        return x.type === 'TimePicker'
      })
      if (timecomp) {
        timecomp.timeRange = timerang[0]
      }
    }
    if (refresh == 'refresh') {
      param.forceRefresh = true
    }
    if (param.timeRanges) {
      param.timeRanges = reqObj.parameter.timeRanges
      if (param.contrastType) {
        const contrastType = reqObj.contrastType
        param.contrastType = contrastType === 'nocontrast' ? 'Current' : contrastType
      }
    }
    cardSearch(card.category, param).then(async res => {
      if (temp == 'Advance') {
        let d = res
        let coderes = ''
        if (d && d.code == '201') {
          coderes = await getAdvanceSearch2(d.data.requestID)
          if (coderes.code == '200') {
            combinjs.searchEnd(_this, chart, cardidx, temp, p, card, coderes.data, '', param)
          }
        } else if (d.code == '200') {
          combinjs.searchEnd(_this, chart, cardidx, temp, p, card, d.data, '', param)
        }
        if (coderes && coderes.code == '202') {
          let searchRes = ''
          searchRes = await getAdvanceSearch2(d.data.requestID)
          if (searchRes && searchRes.code == '202') {
            let searchtimes = 1
            combinjs.recurrence(_this, chart, searchRes, d, cardidx, temp, p, card, searchtimes, param)
          } else if (searchRes.code == '200') {
            d = searchRes
            combinjs.searchEnd(_this, chart, cardidx, temp, p, card, d.data, '', param)
          }
        }
      } else {
        combinjs.searchEnd(_this, chart, cardidx, temp, p, card, res.data, '', param)
      }
    })
  },
  async recurrence(_this, chart, searchRes, d, cardidx, temp, p, card, searchtimes, param) {
    if (searchtimes >= 90) {
      _this.$alert(`查询超时`, '提示', {
        confirmButtonText: '确定',
      })
      return
    }
    try {
      searchRes = await getAdvanceSearch2(d.data.requestID)
    } catch (e) {}
    if (searchRes && searchRes.code == '200') {
      d = searchRes
      combinjs.searchEnd(_this, chart, cardidx, temp, p, card, d.data, '', param)
    } else if (searchRes && searchRes.code == '202') {
      setTimeout(() => {
        searchtimes++
        return combinjs.recurrence(_this, chart, searchRes, d, cardidx, temp, p, card, searchtimes, param)
      }, 2000)
    }
  },
  searchEnd(_this, chart, cardidx, temp, p, card, res, hotmapdata, param) {
    const filterSet = chart.reqObj.cardlist[cardidx].reqObj.settings.filterSet
    // let result = filterSet ? combinjs.getFilterData2(res, chart.reqObj.cardlist[cardidx]) : res
    let result = res
    let option = null
    let opts = {
      ...card.settings,
      analysisType: temp,
    }
    let tableObj = new Table({
      opts,
      columns: result.columns,
      result: result.rows.slice(0, 10000),
    })

    //Table 内部修改了rows 需要同步更新d1
    result.rows = tableObj.params.result
    p.settings = card.settings
    // option = getOptionV2(_this, p, result, opts, tableObj.init(), hotmapdata, chart.reqObj.cardlist[cardidx])
    option = {}
    option.noData = res.rows.length > 0 ? false : true

    chart.reqObj.cardlist[cardidx]['data'] = result
    chart.reqObj.cardlist[cardidx]['orginResult'] = result

    chart.reqObj.cardlist[cardidx]['tableObj'] = tableObj
    chart.reqObj.cardlist[cardidx]['inittableObj'] = tableObj.init()

    chart.reqObj.cardlist[cardidx]['resizedidx'] = 0
    chart.reqObj.cardlist[cardidx]['settings'] = p.settings
    option.color = cardinitMark.initChartColor(_this, chart.reqObj.cardlist[cardidx])
    _this.$set(chart.reqObj.cardlist[cardidx], 'option', option)
    _this.$set(chart.reqObj.cardlist[cardidx], 'rawdata', result)

    chart.loading = false
    _this.$set(chart, 'reqObj', chart.reqObj)
    p.settings = commonJs.compatibleHistory(p.settings)
    const cardinfos = {
      index: 1,
      cardkey: '1',
      cardresizekey: '1',
      parameter: param,
      settings: p.settings,
      result,
      hotmapdata: '',
      projectopts: {
        basicInfo: store.state.basicInfo,
        theme: store.state.basicInfo.project.theme,
        mainColor: store.state.basicInfo.project.mainColor,
        pageunit: store.state.basicInfo.project.axisUnit,
        contrastTrendColor: store.state.basicInfo.project.contrastTrendColor,
        projectMalls: store.state.projectMalls,
        weathers: store.state.weathers,
        festivals: store.state.festivals,
      },
      cardopts: {
        searchCode: chart.searchCode,
        category: temp,
      },
    }
    _this.$set(chart.reqObj.cardlist[cardidx], 'cardinfos', cardinfos)

    _this.emitchart(chart)
  },
}

export default combinjs
