const extractjs = {
  tempstyle(temp) {
    const position = temp.settings.position
    const textStyle = temp.settings.textStyle

    const styles = {}
    if (temp.elementType === 'TextBox') {
      styles['left'] = position.x
      styles['top'] = position.y
      styles['font-size'] = parseInt((textStyle.fontSize / 2) * 3) + 'px'
      styles['color'] = '#' + textStyle.color
      if (textStyle.bold) {
        styles['font-weight'] = 'bold'
      }
      if (position.w) {
        styles['width'] = position.w
      }
      if (position.h) {
        styles['height'] = position.h
      }
      if (temp.slideType === 'chart') {
      }
    } else if (temp.elementType === 'Title') {
      styles['width'] = '73.3%'
      styles['height'] = '8.4%'
      styles['top'] = '7.45%'
    } else if (temp.elementType === 'Image') {
      styles['width'] = position.w
      styles['height'] = position.h
      styles['left'] = position.x
      styles['top'] = position.y
    } else if (temp.elementType === 'Chart') {
      styles['left'] = position.x
      styles['top'] = position.y
      styles['width'] = position.w
      styles['height'] = position.h
    } else if (temp.elementType === 'Catalogue') {
      styles['width'] = position.w
      styles['left'] = position.x
      styles['top'] = position.y
      styles['font-size'] = parseInt((textStyle.fontSize / 2) * 3) + 'px'
      styles['color'] = '#' + textStyle.color
    }
    return styles
  },
}

export default extractjs
